export const SET_LOADER = "SET_LOADER";
export const SET_FAILED_MSG = "SET_FAILED_MSG";
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG";
export const GET_TASK_TABLE = "GET_TASK_TABLE";
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const GET_PAY_RATE = "GET_PAY_RATE";
export const GET_PAY_RATE_HISTORY = "GET_PAY_RATE_HISTORY";
export const GET_MAILING_INFO = "GET_MAILING_INFO";
export const GET_CONTACT_INFO = "GET_CONTACT_INFO";
export const GET_WORK_EXPERIENCE_INFO = "GET_WORK_EXPERIENCE_INFO";
export const GET_EDUCATION_INFO = "GET_EDUCATION_INFO";
export const GET_CERTIFICATION_INFO = "GET_CERTIFICATION_INFO";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_CITIES_LIST = "GET_CITIES_LIST";
export const GET_LANGUAGES_LIST = "GET_LANGUAGES_LIST";
export const GET_ETHNICITY_LIST = "GET_ETHNICITY_LIST";
export const GET_WORK_EXPERIENCE_LIST = "GET_WORK_EXPERIENCE_LIST";
export const GET_PAY_RATE_TYPE_LIST = "GET_PAY_RATE_TYPE_LIST";
export const GET_GENDER_LIST = "GET_GENDER_LIST";
export const GET_ALL_PERSONS_LIST = "GET_ALL_PERSONS_LIST";
export const GET_REGIONS_LIST = "GET_REGIONS_LIST";
export const GET_ADMINS_LIST = "GET_ADMINS_LIST";
export const GET_EDUCATIONAL_SUBJECTS_LIST = "GET_EDUCATIONAL_SUBJECTS_LIST";
export const GET_DEGREES_LIST = "GET_DEGREES_LIST";
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";

export const GET_PREFERRED_SHIFT_TIMINGS_LIST =
  "GET_PREFERRED_SHIFT_TIMINGS_LIST";
export const GET_EMPLOYMENT_TYPE_LIST = "GET_EMPLOYMENT_TYPE_LIST";
export const GET_SPECIALTIES_LIST = "GET_SPECIALTIES_LIST";
export const GET_PROFESSION_LIST = "GET_PROFESSION_LIST";
export const NURSE_ADD_CONTACTS = "NURSE_ADD_CONTACTS";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_PHONE_TYPE_LIST = "GET_PHONE_TYPE_LIST";
export const GET_RELATIONS_LIST = "GET_RELATIONS_LIST";
export const GET_REMINDER_DAYS_LIST = "GET_REMINDER_DAYS_LIST";
export const GET_CERTIFICATE_TYPES_LIST = "GET_CERTIFICATE_TYPES_LIST";
export const GET_EVALUATION_TYPES_LIST = "GET_EVALUATION_TYPES_LIST";
export const GET_ETHNICITIES_LIST = "GET_ETHNICITIES_LIST";
export const GET_PATIENTS_LIST = "GET_PATIENTS_LIST";
export const GET_PATIENTS_WITH_LATLNG_LIST = "GET_PATIENTS_WITH_LATLNG_LIST";
export const GET_NURSES_LIST = "GET_NURSES_LIST";
export const GET_FORM_TEMPLATE_TYPES_LIST = "GET_FORM_TEMPLATE_TYPES_LIST";
export const GET_FORM_TEMPLATES_LIST = "GET_FORM_TEMPLATES_LIST";
export const GET_FREQUENCIES_LIST = "GET_FREQUENCIES_LIST";
export const GET_MARITAL_STATII_LIST = "GET_MARITAL_STATII_LIST";
export const GET_SERVICE_REQUEST_TYPES_LIST = "GET_SERVICE_REQUEST_TYPES_LIST";
export const GET_SERVICE_REQUEST_TASKS_LIST = "GET_SERVICE_REQUEST_TASKS_LIST";
export const GET_PHYSICIANS_LIST = "GET_PHYSICIANS_LIST";
export const GET_HOSPITALS_LIST = "GET_HOSPITALS_LIST";
export const GET_HEALTH_AGENCIES_LIST = "GET_HEALTH_AGENCIES_LIST";
export const GET_HOSPICE_AGENCIES_LIST = "GET_HOSPICE_AGENCIES_LIST";

export const NURSE_ADD_WORK_EXPERIENCE = "NURSE_ADD_WORK_EXPERIENCE";
export const NURSE_WORK_EXPERIENCE = "NURSE_WORK_EXPERIENCE";

// education
export const NURSE_ADD_EDUCATION = "NURSE_ADD_EDUCATION";
export const NURSE_EDUCATION = "NURSE_EDUCATION";
export const NURSE_EMERGENCY_CONTACTS = "NURSE_EMERGENCY_CONTACTS";
