import * as type from "./commonActionTypes";
import {GET_HOSPITALS_LIST} from "./commonActionTypes";

const initialState = {
  loading: false,
  stateList: [],
  languages: [],
  ethnicity: [],
  professionList: [],
  specialtiesList: [],
  employmentTypeList: [],
  preferredShiftTimingsList: [],
  workExperienceList: [],
  payRateTypeList: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LOADER:
      return {
        ...state,
        loading: action.loading,
      };
    case type.GET_STATE_LIST:
      return {
        ...state,
        stateList: action.stateList,
      };
    case type.GET_CITIES_LIST:
      return {
        ...state,
        citiesList: action.stateList,
      };
    case type.GET_LANGUAGES_LIST:
      return {
        ...state,
        languages: action.payload,
      };
    case type.GET_PREFERRED_SHIFT_TIMINGS_LIST:
      return {
        ...state,
        preferredShiftTimingsList: action.payload,
      };
    case type.GET_EMPLOYMENT_TYPE_LIST:
      return {
        ...state,
        employmentTypeList: action.payload,
      };
    case type.GET_SPECIALTIES_LIST:
      return {
        ...state,
        specialtiesList: action.payload,
      };
    case type.GET_PROFESSION_LIST:
      return {
        ...state,
        professionList: action.payload,
      };
    case type.GET_WORK_EXPERIENCE_LIST:
      return {
        ...state,
        workExperienceList: action.payload,
      };
    case type.GET_PAY_RATE_TYPE_LIST:
      return {
        ...state,
        payRateTypeList: action.payload,
      };
    case type.GET_GENDER_LIST:
      return {
        ...state,
        genders: action.payload,
      };
    case type.GET_ALL_PERSONS_LIST:
      return {
        ...state,
        allPersons: action.payload,
      };
    case type.GET_ADMINS_LIST:
      return {
        ...state,
        admins: action.payload,
      };
    case type.GET_REGIONS_LIST:
      return {
        ...state,
        regions: action.payload,
      };
    case type.GET_DEGREES_LIST:
      return {
        ...state,
        degrees: action.payload,
      };
    case type.GET_EDUCATIONAL_SUBJECTS_LIST:
      return {
        ...state,
        courses: action.payload,
      };
    case type.GET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action.payload,
      };
    case type.GET_PHONE_TYPE_LIST:
      return {
        ...state,
        phoneTypesList: action.payload,
      };
    case type.GET_RELATIONS_LIST:
      return {
        ...state,
        relationshipsList: action.payload,
      };
    case type.GET_REMINDER_DAYS_LIST:
      return {
        ...state,
        reminderDaysList: action.payload,
      };
    case type.GET_CERTIFICATE_TYPES_LIST:
      return {
        ...state,
        certificateTypesList: action.payload,
      };
    case type.GET_EVALUATION_TYPES_LIST:
      return {
        ...state,
        evaluationTypesList: action.payload,
      };
    case type.GET_ETHNICITIES_LIST:
      return {
        ...state,
        ethnicitiesList: action.payload,
      };
    case type.GET_PATIENTS_LIST:
      return {
        ...state,
        patientsList: action.payload,
      };
    case type.GET_PATIENTS_WITH_LATLNG_LIST:
      return {
        ...state,
        patientsWithLatLngList: action.payload,
      };
    case type.GET_NURSES_LIST:
      return {
        ...state,
        nursesList: action.payload,
      };
    case type.GET_FORM_TEMPLATE_TYPES_LIST:
      return {
        ...state,
        formTemplateTypesList: action.payload,
      };
    case type.GET_FORM_TEMPLATES_LIST:
      return {
        ...state,
        formTemplatesList: action.payload,
      };
    case type.GET_FREQUENCIES_LIST:
      return {
        ...state,
        frequenciesList: action.payload,
      };
    case type.GET_MARITAL_STATII_LIST:
      return {
        ...state,
        maritalStatusList: action.payload,
      };
    case type.GET_SERVICE_REQUEST_TYPES_LIST:
      return {
        ...state,
        serviceRequestTypesList: action.payload,
      };
    case type.GET_SERVICE_REQUEST_TASKS_LIST:
      return {
        ...state,
        serviceRequestTasksList: action.payload,
      };
    case type.GET_PHYSICIANS_LIST:
      return {
        ...state,
        physiciansList: action.payload,
      };
    case type.GET_HOSPITALS_LIST:
      return {
        ...state,
        hospitalsList: action.payload,
      };
    case type.GET_HEALTH_AGENCIES_LIST:
      return {
        ...state,
        healthAgenciesList: action.payload,
      };
    case type.GET_HOSPICE_AGENCIES_LIST:
      return {
        ...state,
        hospiceAgenciesList: action.payload,
      };
    case `ADD_COMMON_${action.meta?.type}`:
      return {
        ...state,
        [action.name]: action.payload
      };
    default:
      return state;
  }
};

export default commonReducer;
