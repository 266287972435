const postJobsReducer = (state = {}, action) => {
    // if (action.name == null) return state;
    switch (action.type) {
        case 'ADD_POST_JOBS_GET_PROFESSION_LIST':
            let list = action.payload != null && action.payload.data != null ? action.payload.data : [];
            if (list.length > 0) {
                list = list.filter(item => item.id > 1);
            }
            return {
                ...state,
                [action.name]: {...action.payload, data: list},
            };
        case `ADD_POST_JOBS_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state;
    }
};

export default postJobsReducer;
