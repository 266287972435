const agencyProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case `AGENCY_PROFILE_REDUCER_${action.meta?.method}_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action?.payload,
            };
        case `ADD_AGENCY_PROFILE_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state;
    }
};

export default agencyProfileReducer;
