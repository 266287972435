import * as agencyAction from './agencyActionTypes';
import {ADD_FUND_TEMP_DATA, GET_AGENCY_WALLET_AMOUNT} from "./agencyActionTypes";

const initialState = {
    agencyTransactionList: {},
    subscriptionHistoryList: {},
    subscriptionPlansList: {},
    upgradSubscriptionPlan: {},
    addFund: {},
    addFundPaymentOrder: {},
    upgradPlan: {},
    transactionHistory: {}
}

const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case agencyAction.AGENCY_TRANSACTION_LIST:
            return {
                ...state,
                agencyTransactionList: action.payload
            }
        case agencyAction.SUBSCRIPTION_HISTORY_LIST:
            return {
                ...state,
                subscriptionHistoryList: action.payload
            }
        case agencyAction.SUBSCRIPTION_PLAN_LIST:
            return {
                ...state,
                subscriptionPlansList: action.payload
            }
        case agencyAction.PAYPAL_PAYMENT_ORDER:
            return {
                ...state,
                upgradPlan: action.payload
            }
        case agencyAction.UPGRADE_SUBSCRIPTION_PLAN_LIST:
            return {
                ...state,
                upgradSubscriptionPlan: action.payload
            }
        case agencyAction.ADD_FUND:
            return {
                ...state,
                addFund: action.payload
            }
        case agencyAction.ADD_FUND_TEMP_DATA:
            return {
                ...state,
                addFundOrderTempData: action.payload
            }
        case agencyAction.ADD_FUND_PAYMENT_ORDER:
            return {
                ...state,
                addFundPaymentOrder: action.payload
            }
        // case agencyAction.GET_TRANSACTION_HISTORY:
        //     return {
        //         ...state,
        //         transactionHistory: action.payload
        //     }
        case agencyAction.GET_AGENCY_WALLET_AMOUNT:
            return {
                ...state,
                agencyWalletDetails: action.payload
            }

        default:
            return state
    }
}

export default transactionReducer;
