import {
    ADMIN_NURSE_LIST,
    ADMIN_VERIFIED_NURSE_LIST,
    ADMIN_UNVERIFIED_NURSE_LIST,
    ADMIN_WITHDRAWAL_REQUEST_LIST,
    GET_ADMIN_ALL_VERIFIED_NURSE_LIST,
    GET_ADMIN_ALL_UNVERIFIED_NURSE_LIST,
    GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST,
    GET_ADMIN_ALL_NURSE_LIST,
    ADMIN_NURSES_SUMMARY,
    ADMIN_AGENCIES_SUMMARY,
    ADMIN_AGENCIES_LIST,
    GET_ADMIN_ALL_AGENCIES_LIST,
    ADMIN_VERIFIED_AGENCIES_LIST,
    GET_ADMIN_ALL_VERIFIED_AGENCIES_LIST, ADMIN_UNVERIFIED_AGENCIES_LIST, GET_ADMIN_ALL_UNVERIFIED_AGENCIES_LIST,
} from "../types";

const initialState = {
    nurseList: [],
    verifiedNurseList: [],
    unverifiedNurseList: [],
    withdrawalRequest: [],
};

const nurseListingReducer = (state = initialState, action) => {
    switch (action.type) {
// nurses
        case ADMIN_NURSES_SUMMARY:
            return {
                ...state,
                nursesSummary: action.payload,
            };
        case ADMIN_NURSE_LIST:
            return {
                ...state,
                nursesList: action.payload,
            };
        case GET_ADMIN_ALL_NURSE_LIST:
            return {
                ...state,
                allNursesListNoPaging: action.payload,
            };
        case ADMIN_VERIFIED_NURSE_LIST:
            return {
                ...state,
                verifiedNurseList: action.payload,
            };
        case GET_ADMIN_ALL_VERIFIED_NURSE_LIST:
            return {
                ...state,
                allVerifiedNurseList: action.payload,
            };
        case ADMIN_UNVERIFIED_NURSE_LIST:
            return {
                ...state,
                unverifiedNurseList: action.payload,
            };
        case GET_ADMIN_ALL_UNVERIFIED_NURSE_LIST:
            return {
                ...state,
                allUnverifiedNurseList: action.payload,
            };
        case ADMIN_WITHDRAWAL_REQUEST_LIST:
            return {
                ...state,
                withdrawalRequest: action.payload,
            };
        case GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST:
            return {
                ...state,
                allWithdrawalRequest: action.payload,
            };

// agencies
        case ADMIN_AGENCIES_SUMMARY:
            return {
                ...state,
                agenciesSummary: action.payload,
            };
        case ADMIN_AGENCIES_LIST:
            return {
                ...state,
                agenciesList: action.payload,
            };
        case GET_ADMIN_ALL_AGENCIES_LIST:
            return {
                ...state,
                allAgenciesListNoPaging: action.payload,
            };
        case ADMIN_VERIFIED_AGENCIES_LIST:
            return {
                ...state,
                verifiedAgenciesList: action.payload,
            };
        case GET_ADMIN_ALL_VERIFIED_AGENCIES_LIST:
            return {
                ...state,
                allVerifiedAgenciesListNoPaging: action.payload,
            };
        case ADMIN_UNVERIFIED_AGENCIES_LIST:
            return {
                ...state,
                unverifiedAgenciesList: action.payload,
            };
        case GET_ADMIN_ALL_UNVERIFIED_AGENCIES_LIST:
            return {
                ...state,
                allUnverifiedAgenciesListNoPaging: action.payload,
            };

// misc
        case `ADD_ADMIN_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state;
    }
};

export default nurseListingReducer;
