import {isLoggedIn, parseTokenAndGetData} from "./apiMethods";
import adminUrls from "./apiUrls/adminUrls";
import agencyUrls from "./apiUrls/agencyUrls";
import nurseUrls from "./apiUrls/nurseUrls";
import liveUrls from "./apiUrls/liveUrls";
import {apiBaseUrl} from "../appConstants";


export const apiUrls = {
    admin: adminUrls,
    agency: agencyUrls,
    nurse: nurseUrls,
    live: liveUrls,
};


function getApiUrl(key, name, prependBaseUrl = false) {
    let url;
const isUserLoggedIn = isLoggedIn();
// console.log('test- isUserLoggedIn', isUserLoggedIn, key, name)
    if (isUserLoggedIn) {
    // if (isLoggedIn()) {
        const decodedToken = parseTokenAndGetData().data;
// console.log('test- decodedToken', decodedToken?.isAdmin, decodedToken?.isAgency, decodedToken?.isNurse, apiUrls);
        if (decodedToken?.isAdmin) {
            if (apiUrls.admin[key] && apiUrls.admin[key][name]) {
                url = apiUrls.admin[key][name]["url"];
            }
        }
        if (decodedToken?.isAgency) {
            if (apiUrls.agency[key] && apiUrls.agency[key][name]) {
                url = apiUrls.agency[key][name]["url"];
            }
        }
        if (decodedToken?.isNurse) {
            if (apiUrls.nurse[key] && apiUrls.nurse[key][name]) {
                url = apiUrls.nurse[key][name]["url"];
            }
        }
    } else {
        console.log(key, name, "not-logged-in");
        if (apiUrls.live[key] && apiUrls.live[key][name]) {
            url = apiUrls.live[key][name]["url"];
        }
    }

    console.log("test- url ::::::::", url);
    // const nonSecureEndpoints = ['login', 'adminSignup', 'agencySignup', 'nursesSignup'];
    // if (url == null && !isUserLoggedIn && (key !== 'auth' || nonSecureEndpoints.indexOf(name) === -1)) throw new Error('Invalid Session State');

    if (prependBaseUrl) return apiBaseUrl + url;
    return url;
}

export default getApiUrl;
