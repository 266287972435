import * as clientAction from "../Agency/components/Client/clientActionType";

const newPetData = (newIndex) => ({
    recordType: 'new',
    id: '0',
    animalTypeId: '',
    petName: '',
    petAge: '',
    index: newIndex,
});

const newResponsiblePartyContact = (newIndex) => ({
    recordType: 'new',
    id: '0',
    firstName: "",
    lastName: "",
    genderId: 0,
    relationshipId: 0,
    email: "",
    phoneTypeId: 0,
    phoneNumber: "",
    addressLine1: "",
    cityId: 0,
    provinceOrStateId: 0,
    postalOrZipCode: "",
    hipaaDisclosureAuthorization: false,
    medicalDecisions: false,
    index: newIndex,
});

const newDiagnosisItemData = (newIndex) => ({
    id: 0,
    serial: newIndex,
    diagnosis: '',
    diagnosisCode: '',
    // index: newIndex,
    recordType: 'new',
});


const clientReducer = (state = {
    allClients: {},
    petList: [],
    characteristicsList: [],
    responsiblePartyContacts: [],
}, action) => {
    switch (action.type) {
        case clientAction.GET_ALL_CLIENTS:
            return {
                ...state,
                allClients: action.payload,
            };
        case clientAction.GET_CLIENT_BY_ID:
            return {
                ...state,
                clientById: action.payload,
            }
        case clientAction.GET_CARE_INFO_BY_ID:
            return {
                ...state,
                careInfoById: action.payload,
            };
        case clientAction.GET_PERSONAL_INFO_ALL_TITLE:
            return {
                ...state,
                personalInfoAllTitle: action.payload,
            };
        case clientAction.GET_PREFERRED_CAREGIVER:
            return {
                ...state,
                preferredCaregiver: action.payload,
            };
        case clientAction.GET_RESIDENTIAL_INFO_ADDRESSES:
            return {
                ...state,
                residentialInfoAddress: action.payload,
            };
        case clientAction.GET_CONTACT_INFO_ADDRESS:
            return {
                ...state,
                contactInfoAddress: action.payload,
            };
        case clientAction.GET_PET_INFO:
            // console.log('test- payload', action.payload)
            return {
                ...state,
                petList: (action.payload || []).map(p => ({...p, recordType: p.recordType || 'existing'})),
            };
        case clientAction.GET_CITY_LIST:
            return {
                ...state,
                cityList: action.payload,
            };
        case clientAction.GET_BILLING_INFO_ADDRESSES:
            return {
                ...state,
                billingInfoAddress: action.payload,
            };
        case clientAction.SET_PERSONAL_PROFILE_AGE:
            return {
                ...state,
                personalInfoAllAge: action.payload,
            };
        case clientAction.ADD_PET:
            // console.log('test- petList', state.petList)
            let petsCount = 0;
            if (state.petList != null && Array.isArray(state.petList) && state.petList.length > 0) {
                petsCount = Math.max(...state.petList.map(o => o.index)) + 1;
            }
            return {
                ...state,
                // ...state.petList,
                petList: [...state.petList || [], newPetData(petsCount)],
            };
        case clientAction.GET_RESP_PARTY_CONTACTS:
// console.log('test- payload', action.payload)
            return {
                ...state,
                responsiblePartyContacts: (action.payload || []).map(p => ({...p, recordType: p.recordType || 'existing'})),
            };
        case clientAction.ADD_RESP_PART_CONTACT:
// console.log('test- responsiblePartyContacts', state.responsiblePartyContacts)
            let respPartContactsCount = 0;
            if (state.responsiblePartyContacts != null && Array.isArray(state.responsiblePartyContacts) && state.responsiblePartyContacts.length > 0) {
                respPartContactsCount = Math.max(...state.responsiblePartyContacts.map(o => o.index)) + 1;
            }
            return {
                ...state,
                responsiblePartyContacts: [...state.responsiblePartyContacts || [], newResponsiblePartyContact(respPartContactsCount)],
            };
        case clientAction.GET_HEALTHCARE_EXTRA_INFO:
// console.log('test- payload', action.payload)
            return {
                ...state,
                // healthcareExtraInformation: {medicationInformation: action.payload?.healthcareMedicationDetails || '', medicalNotes: action.payload?.healthcareMedicalNotes || '', diagnosisList: (action.payload?.healthcareDiagnosisList || []).map(p => ({...p, recordType: p.recordType || 'existing'}))},
                healthcareExtraInformation: action.payload,
            };
        // case clientAction.ADD_HEALTHCARE_DIAGNOSIS:
        //     let diagnosisCount = 0;
        //     if (state.diagnosisList != null && Array.isArray(state.diagnosisList) && state.diagnosisList.length > 0) {
        //         diagnosisCount = Math.max(...state.diagnosisList.map(o => o.index)) + 1;
        //     }
        //     return {
        //         ...state,
        //         diagnosisList: [...state.diagnosisList || [], newDiagnosisItemData(diagnosisCount)],
        //     };
        case clientAction.ADD_HEALTHCARE_DIAGNOSIS:
            let diagnosisCount = 0;
            if (state.healthcareExtraInformation != null && state.healthcareExtraInformation.diagnosisList != null && Array.isArray(state.healthcareExtraInformation.diagnosisList) && state.healthcareExtraInformation.diagnosisList.length > 0) {
                diagnosisCount = Math.max(...state.healthcareExtraInformation.diagnosisList.map(o => o?.index || 0)) + 1;
            }
            return {
                ...state,
                healthcareExtraInformation: {...state.healthcareExtraInformation, diagnosisList: [...state?.healthcareExtraInformation?.diagnosisList || [], newDiagnosisItemData(diagnosisCount)]},
            };
        case `ADD_CLIENT_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state;
    }
};

export default clientReducer;
