const stringHelperFunctions = {

  sanitize(value, nullThenReturnEmptyString = true, nullLiteralsForCompare = [], defaultValue = '') {
  // console.log('test- nullLiteralsForCompare', nullLiteralsForCompare)
    let valueIsNull = value == null;
    if (value != null && value.length > 0) {
      if (!nullLiteralsForCompare.includes(value.trim())) {
        return value;
      }
      valueIsNull = true;
    }
    if (valueIsNull) {
      if (nullThenReturnEmptyString) {
        return '';
      }
      return defaultValue;
    }
    return null;
  },

  csvfy(strVal, isLast = false) {
    return strVal != null ? (isLast ? strVal : `${strVal}, `) : '';
  },

  camelCaseToWords(s) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  },

  camelCaseToSnakeCase(s) {
    const result = s.replace(/([A-Z])/g, '_$1');
    return result.toLowerCase();
  },

  truncateString(str, n = 30, useWordBoundary = false) {
    if (str.length <= n) { return str; }
    const subString = str.slice(0, n-1); // the original check
    return (useWordBoundary
        ? subString.slice(0, subString.lastIndexOf(" "))
        : subString)
      // + "&hellip;";
      + "...";
  },

  nullIfEmpty(value) {
    if (value != null) {
      return value.length === 0 ? null : value.trim();
    }
    return null;
  },

  urlToDashCase(s) {
    const result = s.replace(/\//g, '-');
    return result.toLowerCase().substring(1);
  },


};

export default stringHelperFunctions;
