export const ALL_SERVICE_REQUESTS_LIST = 'ALL_SERVICE_REQUESTS_LIST';
export const ACTIVE_SERVICE_REQUESTS_LIST = 'ACTIVE_SERVICE_REQUESTS_LIST';
export const PENDING_SERVICE_REQUESTS_LIST = 'PENDING_SERVICE_REQUESTS_LIST';
export const SCHEDULED_SERVICE_REQUESTS_LIST = 'SCHEDULED_SERVICE_REQUESTS_LIST';
export const COMPLETED_SERVICE_REQUESTS_LIST = 'COMPLETED_SERVICE_REQUESTS_LIST';
export const MISSED_SERVICE_REQUESTS_LIST = 'MISSED_SERVICE_REQUESTS_LIST';
export const INCOMPLETE_VISITS_LIST = 'INCOMPLETE_VISITS_LIST';
export const POSTED_JOBS = 'POSTED_JOBS';
export const POSTED_JOBS_ACTIVE = 'POSTED_JOBS_ACTIVE';
export const POSTED_JOBS_ACCEPTED = 'POSTED_JOBS_ACCEPTED';
export const POSTED_JOBS_IN_PROGRESS = 'POSTED_JOBS_IN_PROGRESS';
export const POSTED_JOBS_EXPIRED = 'POSTED_JOBS_EXPIRED';
export const GET_INITIAL_ASSESSMENTS = 'GET_INITIAL_ASSESSMENTS';
export const GET_SUPERVISORY_VISITS = 'GET_SUPERVISORY_VISITS';
export const GET_DISCHARGE_SUMMARIES = 'GET_DISCHARGE_SUMMARIES';
export const GET_CARE_PLANS = 'GET_CARE_PLANS';
