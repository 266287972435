import axios from "axios";
import { setResponseInterceptor } from "../utils/response-interceptor";
import { apiBaseUrl } from "../appConstants";
import storageManager from "./storageManager";

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  const decoded = JSON.parse(jsonPayload);
  delete decoded["iat"];
  delete decoded["exp"];
  return decoded;
}

function addRequiredHeaders(headers, addAuthHeader = false) {
  headers["ClientIP"] = "123.123.123.123";
  headers["ClientPlatform"] = "Web";

  const token = storageManager.retrieveToken();
  // console.log("token is null", token == null, token != null ? token.length : 0);
  if (token != null) {
    const decodedToken = parseJwt(token);
    // console.log("decoded-token", decodedToken);
    headers["User-ID"] = decodedToken.id;
    if (addAuthHeader) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  }
}

const commonGetDeleteHeaders = (addAuthHeader = true) => {
  const headers = {
    "Accept": "application/json",
  };
  addRequiredHeaders(headers, addAuthHeader);
  return headers;
};

export const commonPostPutHeaders = (addAuthHeader = true, isMultipartFormData = false) => {
  let headers = {
    "Accept": "application/json",
  };
  headers = {
    ...headers,
    "Content-Type": isMultipartFormData ? "multipart/form-data" : "application/json",
  };
  addRequiredHeaders(headers, addAuthHeader);
  return headers;
};

class ApiClient {
  static instance = null;
  static api = null;

  static getApiInstance() {
    if (ApiClient.api == null) {
      ApiClient.api = axios.create({
        baseURL: apiBaseUrl,
      });

      // Add a request interceptor
      ApiClient.api.interceptors.request.use(
        (config) => {
          // You can modify the request config here (e.g., add headers)
          return config;
        },
        (error) => {
          // Handle request errors (e.g., network issues)
          return Promise.reject(error);
        }
      );

      // Add a response interceptor
      setResponseInterceptor(ApiClient.api);
    }
    return ApiClient.api;
  }

  static getInstance() {
    if (ApiClient.instance == null) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  get = (url, addAuthHeader = true) => {
    console.log(`GET API call - ${url}`);
    return ApiClient.getApiInstance().get(url, {
      headers: {
        ...commonGetDeleteHeaders(addAuthHeader),
      },
      withCredentials: false,
    });
  };

  post = (url, data, isMultipartFormData = false) => {
    console.log(`POST API call - ${url}`);
    return ApiClient.getApiInstance().post(url, data, {
      headers: {
        ...commonPostPutHeaders(true, isMultipartFormData),
      },
      withCredentials: false,
    });
  };

  put = (url, data, isMultipartFormData = false) => {
    console.log(`POST API call - ${url}`);
    return ApiClient.getApiInstance().put(url, data, {
      headers: {
        ...commonPostPutHeaders(true, isMultipartFormData),
      },
      withCredentials: false,
    });
  };

  delete = (url, data) => {
    console.log(`POST API call - ${url}`);
    return ApiClient.getApiInstance().delete(url, {
      headers: {
        ...commonGetDeleteHeaders(),
      },
      withCredentials: false,
    });
  };
}

export default ApiClient.getInstance();
