import {refreshTokenVarName, tokenVarName} from "../appConstants";

class StorageManager {

  static ENCRYPTION_KEY = process.env.REACT_APP_STORAGE_ENCRYPTION_KEY;

  constructor() { }

  clear() {
    sessionStorage.clear();
  }

  async saveToken(token, refreshToken) {
    sessionStorage.setItem(tokenVarName, token);
    localStorage.setItem(refreshTokenVarName, refreshToken);
    // const tokenEncrypted = await Crypto.encrypt(StorageManager.ENCRYPTION_KEY, token);
    // const refreshTokenEncrypted = await Crypto.encrypt(StorageManager.ENCRYPTION_KEY, refreshToken);
    // sessionStorage.setItem(tokenVarName, tokenEncrypted);
    // localStorage.setItem(refreshTokenVarName, refreshTokenEncrypted);
  }

  retrieveToken() {
    return sessionStorage.getItem(tokenVarName);
    // const tokenEncrypted = sessionStorage.getItem(tokenVarName)
    // if (tokenEncrypted) {
    //   return Crypto.decrypt(StorageManager.ENCRYPTION_KEY, tokenEncrypted);
    // }
    // return null;
  }

  saveClientListTotalPages(count) {
    sessionStorage.setItem('client-list-pages-count', String(count));
  }

  retrieveClientListTotalPages() {
    return sessionStorage.getItem('client-list-pages-count');
  }

  saveClientListCurrentPage(page) {
    sessionStorage.setItem('client-list-page-num', String(page));
  }

  retrieveClientListCurrentPage() {
    return sessionStorage.getItem('client-list-page-num');
  }

  saveInternalNurseListCurrentPage(page) {
    sessionStorage.setItem('internal-nurse-list-page-num', String(page));
  }

  retrieveInternalNurseListCurrentPage() {
    return sessionStorage.getItem('internal-nurse-list-page-num');
  }

  saveHiredNurseListCurrentPage(page) {
    sessionStorage.setItem('hired-nurse-list-page-num', String(page));
  }

  retrieveHiredNurseListCurrentPage() {
    return sessionStorage.getItem('hired-nurse-list-page-num');
  }

  saveProfileImageLink(link, dispatch = false) {
    sessionStorage.setItem('profile-image-link', link);
    if (dispatch) {
      window.dispatchEvent(new CustomEvent('storage', { detail: {key: 'profile-image-link'} }))
    }
  }

  retrieveProfileImageLink() {
    return sessionStorage.getItem('profile-image-link');
  }

  savePaypalOrderData(data, useLocalStorage = false) {
    if (!useLocalStorage)
      sessionStorage.setItem('paypal-order-data', JSON.stringify(data));
    else
      localStorage.setItem('paypal-order-data', JSON.stringify(data));
  }

  retrievePaypalOrderData(useLocalStorage = false) {
    if (!useLocalStorage)
      return sessionStorage.getItem('paypal-order-data');
    else
      return localStorage.getItem('paypal-order-data');
  }

  saveAdminBackLinkInfo(data, useLocalStorage = false) {
    if (!useLocalStorage)
      sessionStorage.setItem('admin-back-link-info', JSON.stringify(data));
    else
      localStorage.setItem('admin-back-link-info', JSON.stringify(data));
  }

  retrieveAdminBackLinkInfo(useLocalStorage = false) {
    if (!useLocalStorage)
      return sessionStorage.getItem('admin-back-link-info');
    else
      return localStorage.getItem('admin-back-link-info');
  }

  saveUpdateBalance(balanceValue, dispatch = false) {
    sessionStorage.setItem('balance-updated', String(balanceValue));
    if (dispatch) {
      window.dispatchEvent(new CustomEvent('storage', { detail: {key: 'balance-updated'} }))
    }
  }

  retrieveUpdateBalance() {
    return sessionStorage.getItem('balance-updated');
  }

}

// courtesy: https://www.digitalocean.com/community/tutorials/js-js-singletons
const storageManagerSingletonInstance = new StorageManager();

Object.freeze(storageManagerSingletonInstance);

export default storageManagerSingletonInstance;
