// commonNew.js
import { useQuery } from "@tanstack/react-query";
import api from "../helpers/interceptor";
import getApiUrl from "../helpers/apiURLs";
import {defaultQueryOptions} from "../utils/queryClient";

export function useGendersList() {
  // return useQuery(['list-genders'], () => {
  //     const url = getApiUrl("combos", "gendersList");
  //     return api
  //       .get(url)
  //       .then((res) => res.data);
  //   },
  // );
  return useQuery({
    queryKey: ['list-genders'],
    queryFn: () => {
      const url = getApiUrl("combos", "gendersList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });

  // const initialDataFunc = async () => {
  //   const url = getApiUrl("combos", "gendersList");
  //   return (await api.get(url)).data;
  // };
  // return useQuery({
  //   queryKey: ['list-genders'],
  //   queryFn: initialDataFunc,
  //   ...defaultQueryOptions,
  //   enabled: false,
  //   // staleTime: Infinity,
  //   // cacheTime: Infinity,
  //   placeholderData: [],
  // });
}

export function useProvincesOrStatesList(countryId = 233) {
  // if (countryId == null || countryId.length === 0) return [];
  // if (typeof countryId === 'string') countryId = +countryId;
  return useQuery({
    // queryKey: [`list-provinces-states-${countryId}`],
    queryKey: ['list-provinces-states', countryId],
    queryFn: () => {
      const url = getApiUrl("combos", "provincesOrStatesList");
      return api
        .get(`${url}/${countryId}`)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useProfessionsList() {
  return useQuery({
    queryKey: ['list-professions'],
    queryFn: () => {
      const url = getApiUrl("combos", "professionsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useLanguagesList() {
  return useQuery({
    queryKey: ['list-languages'],
    queryFn: () => {
      const url = getApiUrl("combos", "languagesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useOtherLanguagesList() {
  return useQuery({
    queryKey: ['list-other-languages'],
    queryFn: () => {
      const url = getApiUrl("combos", "languagesList");
      return api
        .get(url)
        .then((res) => res.data.filter(item => item.id > 1));
      // const languagesArray = queryClient.getQueryData('list-languages');
      // return languagesArray.filter(item => item.id > 1);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useEthnicitiesList() {
  return useQuery({
    queryKey: ['list-ethnicities'],
    queryFn: () => {
      const url = getApiUrl("combos", "ethnicitiesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function usePersonalTitlesList() {
  return useQuery({
    queryKey: ['list-personal-titles'],
    queryFn: () => {
      const url = getApiUrl("combos", "personalTitlesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

//countries, cities, nurse-skills,


export function useAnimalTypesList() {
  return useQuery({
    queryKey: ['list-animal-types'],
    queryFn: () => {
      const url = getApiUrl("combos", "animalTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useMaritalStatusList() {
  return useQuery({
    queryKey: ['list-marital-status'],
    queryFn: () => {
      const url = getApiUrl("combos", "maritalStatusList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useEduDegreesList() {
  return useQuery({
    queryKey: ['list-edu-degrees'],
    queryFn: () => {
      const url = getApiUrl("combos", "degreesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useEduCoursesList() {
  return useQuery({
    queryKey: ['list-edu-courses'],
    queryFn: () => {
      const url = getApiUrl("combos", "coursesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function usePhoneTypesList() {
  return useQuery({
    queryKey: ['list-phone-types'],
    queryFn: () => {
      const url = getApiUrl("combos", "phoneTypeList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useRelationshipsList() {
  return useQuery({
    queryKey: ['list-relationships'],
    queryFn: () => {
      const url = getApiUrl("combos", "relationshipsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useReminderDaysList() {
  return useQuery({
    queryKey: ['list-reminder-days'],
    queryFn: () => {
      const url = getApiUrl("combos", "reminderDaysList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useFormTemplateTypesList() {
  return useQuery({
    queryKey: ['list-form-template-types'],
    queryFn: () => {
      const url = getApiUrl("combos", "formTemplateTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useFormTemplatesList(agencyId = 0, type) {
  let url = getApiUrl("combos", "formTemplatesList");
  if (type != null && type.length > 0) {
    url += `?chartingType=${type}`;
  }
  return useQuery({
    queryKey: ['list-form-templates', agencyId, type],
    queryFn: () => {
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useDayFrequenciesList() {
  return useQuery({
    queryKey: ['list-day-frequencies'],
    queryFn: () => {
      const url = getApiUrl("combos", "frequencyList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useTriageLevelsList() {
  return useQuery({
    queryKey: ['list-triage-levels'],
    queryFn: () => {
      const url = getApiUrl("combos", "triageLevelsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useEmploymentTypesList() {
  return useQuery({
    queryKey: ['list-employment-types'],
    queryFn: () => {
      const url = getApiUrl("combos", "employmentTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useWorkExperienceYearsList() {
  return useQuery({
    queryKey: ['list-experience-years'],
    queryFn: () => {
      const url = getApiUrl("combos", "workExperienceYearsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useShiftTimingsList() {
  return useQuery({
    queryKey: ['list-shift-timings'],
    queryFn: () => {
      const url = getApiUrl("combos", "shiftTimingsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}


// export function useAuthData(/*success, initialData*/) {
//   // const initialData = success ? initialData : undefined;
//   const initialData = undefined;
//   return useQuery({
//     queryKey: ['data-auth'],
//     queryFn: () => initialData,
//     ...defaultQueryOptions,
//     enabled: false,
//     // staleTime: Infinity,
//     // cacheTime: Infinity,
//     // placeholderData: [],
//     initialData,
//   });
// }




export function useCitiesList(provinceOrStateId = 0) {
  // if (provinceOrStateId == null || provinceOrStateId.length === 0) return [];
  // if (typeof provinceOrStateId === 'string') provinceOrStateId = +provinceOrStateId;
  return useQuery({
    // queryKey: [`list-cities-${provinceOrStateId}`],
    queryKey: ['list-cities', provinceOrStateId],
    queryFn: () => {
      const url = getApiUrl("combos", "citiesList");
      return api
        .get(`${url}/${provinceOrStateId}`)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: true,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useSpecialitiesList(classId = 0) {
  // if (classId == null || classId.length === 0) return [];
  // if (typeof classId === 'string') classId = +classId;
  return useQuery({
    // queryKey: [`list-skills-${classId}`],
    queryKey: ['list-skills', classId],
    queryFn: () => {
      const url = getApiUrl("combos", "specialitiesList");
      return api
        .get(`${url}/${classId}`)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: true,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}


//// secure queries below

export function useCertificateTypesList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-certificate-types', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "certificateTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useEvaluationTypesList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-evaluation-types', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "evaluationTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function usePhysicianTypesList(agencyId = 0) {
// console.log('test- usePhysicianTypesList called')
  return useQuery({
    queryKey: ['list-physician-types', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "physicianTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useServiceRequestTypesList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-service-request-types', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "serviceRequestTypesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useServiceRequestTasksList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-service-request-tasks', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "serviceRequestTasksList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    placeholderData: [],
  });
}

export function usePatientsListForAgency(agencyId = 0) {
  return useQuery({
    queryKey: ['list-patients', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "clientsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData: [],
  });
}

export function usePatientsListForAgencyWithLatLong(agencyId = 0) {
  return useQuery({
    queryKey: ['list-patients-with-latlng', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "clientsWithLatLongList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData: [],
  });
}

export function useNursesListForAgency(agencyId = 0) {
  return useQuery({
    queryKey: ['list-nurses', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "nursesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData: [],
  });
}

export function useRegionsList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-regions', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "regionsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useAdminsList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-admins', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "adminsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function usePersonsList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-persons', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "allPersonsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function usePhysiciansList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-physicians', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "physiciansList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useHospitalsList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-hospitals', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "hospitalsList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useHomeHealthAgenciesList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-home-health-agencies', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "homeHealthAgenciesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useHospiceAgenciesList(agencyId = 0) {
  return useQuery({
    queryKey: ['list-hospice-agencies', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "hospiceAgenciesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: [],
  });
}

export function useInternalNursesListForAgency(agencyId = 0) {
  return useQuery({
    queryKey: ['list-internal-nurses', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "internalNursesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData: [],
  });
}

export function useFreelanceNursesListForAgency(agencyId = 0) {
  return useQuery({
    queryKey: ['list-freelance-nurses', agencyId],
    queryFn: () => {
      const url = getApiUrl("combos", "freelanceNursesList");
      return api
        .get(url)
        .then((res) => res.data);
    },
    ...defaultQueryOptions,
    enabled: agencyId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData: [],
  });
}
