import * as nurseAction from "./nurseActionTypes";
import {NURSE_OTHER_CERTIFICATIONS} from "./nurseActionTypes";

const newEducationInfoRecord = {
  id: '0',
  recordType: "new",
  institutionName: "",
  location: "",
  tenureStartDate: "",
  tenureEndDate: "",
  degreeId: '0',
  majorEducationalSubjectId: '0',
  description: "",
};

const newNurseEmergencyContact = {
  id: '0',
  recordType: "new",
  firstName: "",
  lastName: "",
  relationshipId: "",
  address: "",
  provinceOrStateId: "",
  cityId: "",
  postalOrZipCode: "",
  // email: "",
  // phoneNumbers: "",
  phoneTypeId: "",
  phoneNumber: "",
};

const newWorkExperienceRecord = {
  recordType: "new",
  id: '0',
  companyName: "",
  companyAddress: "",
  employerName: "",
  employerPhoneNumber: "",
  startDate: "",
  endDate: "",
  duties: "",
  reasonForLeaving: "",
  canContactEmployer: "",
  hasIncontinenceExperience: "",
  hasHoyerLiftExperience: "",
  hasCompanionshipExperience: "",
  hasDrivingExperience: "",
  hasBathingExperience: "",
  hasDressingExperience: "",
  hasAlzheimerExperience: "",
  hasDementiaExperience: "",
  hasTransferAssistExperience: "",
  hasSmokingExperience: "",
  hasMalesExperience: "",
  hasFemalesExperience: "",
  hasCatsExperience: "",
  hasGaitBeltExperience: "",
  hasDogsExperience: "",
  hasAnyOtherExperience: "",
  anyOtherExperienceDetails: "",
};

const initialState = {
  nurseList: {},
  nurseProfile: {},
  nurseAccountDetails: {},
  nurseMailingInfo: {},
  nurseAdditionalQuestions: {},
  nurseEmergencyContacts: null,
  nurseEducationInfo: null,
  nurseDetail: {},
  phoneTypeList: [],
};

const newCertificationRecord = {
  recordType: "new",
  id: '0',
  checkIfComplete: false,
  certificationEvaluationId: '0',
  expiryDate: "",
  reminderDaysId: '0',
  note: "",
  renewalRequired: 'false',
  renewalDate: "",
  renewalReminderDaysId: '0',
};

const newOtherCertificationRecord = {
  id: '0',
  recordType: "new",
  otherCertificationName: "",
  expiryDate: "",
  reminderDaysId: '0',
  note: "",
  renewalRequired: false,
  renewalDate: "",
  renewalReminderDaysId: '0',
  checkIfComplete: false
};

const newEvaluationRecord = {
  id: '0',
  recordType: "new",
  certificationEvaluationId: '0',
  expiryDate: "",
  reminderDaysId: '0',
  note: "",
  renewalRequired: false,
  renewalDate: "",
  renewalReminderDaysId: '0',
  checkIfComplete: false
};

const nurseListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case nurseAction.NURSE_LIST:
      return {
        ...state,
        ...initialState,
        nurseList: action.payload,
      };
    case nurseAction.PHONE_TYPES:
      return {
        ...state,
        ...initialState,
        phoneTypeList: action.payload,
      };
    case nurseAction.NURSE_PROFILE:
      return {
        ...state,
        nurseProfile: action.payload,
      };
    case nurseAction.NURSE_ACCOUNT_DETAILS:
      return {
        ...state,
        nurseAccountDetails: action.payload,
      };
    case nurseAction.NURSE_MAILING_INFO:
      return {
        ...state,
        nurseMailingInfo: action.payload,
      };
    case nurseAction.NURSE_ADDITIONAL_QUESTIONS:
      return {
        ...state,
        nurseAdditionalQuestions: action.payload,
      };
    case nurseAction.NURSE_WORK_EXPERIENCE:
      // console.log('test- GET', action.payload)
      return {
        ...state,
        nurseWorkExperience: action.payload,
     };
    case nurseAction.NURSE_ADD_WORK_EXPERIENCE:
      // console.log('test- ADD', action.payload)
      const weDataObj = (state.nurseWorkExperience || {xtraField: '0', list: []});
      return {
        ...state,
        // nurseWorkExperience: [
        //   ...(state.nurseWorkExperience || []),
        //   newWorkExperienceRecord,
        // ],
        nurseWorkExperience: {
          xtraField: weDataObj.xtraField,
          list: [...weDataObj.list, newWorkExperienceRecord],
        },
      };
    case nurseAction.NURSE_EMERGENCY_CONTACTS:
      return {
        ...state,
        nurseEmergencyContacts: action.payload,
      };
    case nurseAction.NURSE_ADD_EMERGENCY_CONTACTS:
      const ecDataObj = (state.nurseEmergencyContacts || {xtraField: '0', list: []});
      return {
        ...state,
        // nurseEmergencyContacts: [
        //   ...state.nurseEmergencyContacts,
        //   newNurseEmergencyContact,
        // ],
        nurseEmergencyContacts: {
          xtraField: ecDataObj.xtraField,
          list: [...ecDataObj.list, newNurseEmergencyContact],
        },
      };
    case nurseAction.NURSE_EDUCATION:
      // console.log('test- GET', action.payload)
      return {
        ...state,
        nurseEducationInfo: action.payload,
      };
    case nurseAction.NURSE_ADD_EDUCATION:
      // console.log('test- ADD', state.nurseEducationInfo)
      const eduDataObj = (state.nurseEducationInfo || {xtraField: '0', list: []});
      return {
        ...state,
        // nurseEducationInfo: [
        //   ...state.nurseEducationInfo,
        //   newEducationInfoRecord,
        // ],
        nurseEducationInfo: {
          xtraField: eduDataObj.xtraField,
          list: [...eduDataObj.list, newEducationInfoRecord],
        },
      };
    case nurseAction.NURSE_DETAIL:
      return {
        ...state,
        nurseDetail: action.payload,
      };
    case nurseAction.NURSE_ADD_CONTACTS:
      return {
        ...state,
        ...state.contactInfo,
        nurseEmergencyContacts: [
          ...state.nurseEmergencyContacts || [],
          {
            firstName: "",
            relationshipId: "",
            address: "",
            provinceOrStateId: '0',
            cityId: '0',
            postalOrZipCode: "",
            email: "",
            phoneNumbers: [
              { phoneTypeId: '0', phoneNumber: "" },
              { phoneTypeId: '0', phoneNumber: "" },
            ],
          },
        ],
      };
    // case nurseAction.GET_PHONE_TYPE_LIST:
    //   return {
    //     ...state,
    //     phoneTypeList: action.payload,
    //   };
    case nurseAction.NURSE_CERTIFICATIONS:
// console.log('test- data-payload', action.payload)
      return {
        ...state,
        nurseCertifications: action.payload,
        // nurseCertifications: (action.payload || []).map(p => ({
        //   ...p,
        //   checkIfComplete: true,
        //   recordType: p.recordType || 'existing',
        //   // id: p.id,
        //   // certificationEvaluationId: p.nurseCertificationId,
        //   // expiryDate: p.nurseCertificationExpiryDate,
        //   // reminderDaysId: p.nurseCertificationReminderDaysId,
        //   // note: p.nurseCertificationNote,
        //   // renewalRequired: p.nurseCertificationRenewalRequired,
        //   // renewalDate: p.nurseCertificationRenewalDate,
        //   // renewalReminderDaysId: p.nurseCertificationRenewalReminderDaysId,
        // })),
      };
    case nurseAction.NURSE_ADD_CERTIFICATION:
      // console.log('test- ADD', state.nurseCertifications)
      const certDataObj = (state.nurseCertifications || {xtraField: '0', list: []});
      return {
        ...state,
        // nurseCertifications: [
        //   ...(state.nurseCertifications || []),
        //   newCertificationRecord,
        // ],
        nurseCertifications: {
          xtraField: certDataObj.xtraField,
          list: [...certDataObj.list, newCertificationRecord],
        },
      };
    case nurseAction.NURSE_EVALUATIONS:
      return {
        ...state,
        nurseEvaluations: action.payload,
        // nurseEvaluations: (action.payload || []).map(p => ({...p, checkIfComplete: true, recordType: p.recordType || 'existing'})),
      };
    case nurseAction.NURSE_ADD_EVALUATION:
// console.log('test- data-payload', action, state.nurseEvaluations)
      const evalDataObj = (state.nurseEvaluations || {xtraField: '0', list: []});
      return {
        ...state,
        // nurseEvaluations: [
        //   ...(state.nurseEvaluations || []),
        //   newEvaluationRecord,
        // ],
        nurseEvaluations: {
          xtraField: evalDataObj.xtraField,
          list: [...evalDataObj.list, newEvaluationRecord],
        },
      };
    case nurseAction.NURSE_OTHER_CERTIFICATIONS:
      return {
        ...state,
        nurseOtherCertifications: action.payload,
        // nurseOtherCertifications: (action.payload || []).map(p => ({...p, checkIfComplete: true, recordType: p.recordType || 'existing'})),
      };
    case nurseAction.NURSE_ADD_OTHER_CERTIFICATION:
      const oCertDataObj = (state.nurseOtherCertifications || {xtraField: '0', list: []});
      return {
        ...state,
        // nurseOtherCertifications: [
        //   ...(state.nurseOtherCertifications || []),
        //   newOtherCertificationRecord,
        // ],
        nurseOtherCertifications: {
          xtraField: oCertDataObj.xtraField,
          list: [...oCertDataObj.list, newOtherCertificationRecord],
        },
      };
    case `ADD_NURSE_${action.meta?.type}`:
      return {
        ...state,
        [action.name]: action.payload,
      };
    default:
      return state;
  }
};

export default nurseListingReducer;
