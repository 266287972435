import { applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from "redux-promise";
import thunk from 'redux-thunk';
import reducer from './rootReducer'; // Ensure that the path to your reducer is correct.
import middlewares from './global/middlewares'; // Ensure that the path to your middlewares is correct.
import config from './global/config';

const { isDev, isBrowser } = config;

// DevTools setup (using redux-devtools-extension)
const composeEnhancers = (isDev && isBrowser && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const configureStore = (initialState = {}, services = {}) => {
    const enhancers = [
        applyMiddleware(...middlewares, thunk, promiseMiddleware),
    ];

    const store = createStore(
        reducer,
        initialState,
        composeEnhancers(...enhancers)
    );

    return store;
};

export default configureStore;