export const MESSAGE_LIST = 'MESSAGE_LIST'
export const USER_MESSAGES_LIST = 'USER_MESSAGES_LIST'
export const USER_CHAT_MESSAGES_LIST = 'USER_CHAT_MESSAGES_LIST'
export const NEW_MESSAGE_FOR_UNSELECTED_USER = 'NEW_MESSAGE_FOR_UNSELECTED_USER'
export const UNREAD_CHAT_MESSAGES_IDS = 'UNREAD_CHAT_MESSAGES_IDS'
export const USER_READ_MESSAGES = 'USER_READ_MESSAGES'
export const MAKE_AN_OFFER = 'MAKE_AN_OFFER'
export const ACCEPT_DECLINE_OFFER = 'ACCEPT_DECLINE_OFFER'
export const TRANSACTION_HISTORY = 'TRANSACTION_HISTORY'
export const AGENCY_TRANSACTION_LIST = 'AGENCY_TRANSACTION_LIST'
export const ADD_SAVED_CHAT_MESSAGE = 'ADD_SAVED_CHAT_MESSAGE'






