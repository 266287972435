import ErrorPage from "./ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

const ReactErrorBoundary = (props) => (
  <ErrorBoundary
    FallbackComponent={ErrorPage}
    onError={(error, errorInfo) => {
      // log the error
      console.log("Error caught!");
      console.error(error);
      console.error(errorInfo);
    }}
    onReset={() => {
      // reloading the page to restore the initial state
      // of the current page
      console.log("reloading the page...");
      window.location.reload();

      // other reset logic...
    }}
  >
    {props.children}
  </ErrorBoundary>
);

export default ReactErrorBoundary;
