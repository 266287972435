// always accessible
const combosLive = {
  gendersList: {
    url: '/lookups/genders/combo'
  },
  provincesOrStatesList: {
    url: '/lookups/provinces-states/combo'
  },
  professionsList: {
    url: `/lookups/nurse-classes/combo`,
  },
  languagesList: {
    url: '/lookups/languages/combo'
  },
  ethnicitiesList: {
    url: '/lookups/ethnicities/combo'
  },
  personalTitlesList: {
    url: `/lookups/personal-titles/combo`
  },
  citiesList: {
    url: '/lookups/cities/combo'
  },
  specialitiesList: {
    url: `/lookups/nurse-skills/combo`,
  },
  //'/lookups/countries/combo'
};

export default {
  combosLive,
  auth: {
    login: {
      url: '/login'
    },
    adminSignup: {
      url: '/admin/signup'
    },
    agencySignup: {
      url: '/agency/signup'
    },
    nursesSignup: {
      url: '/nurses/signup'
    },
    generateVerifyEmailPasscode: {
      url: '/check-email'
    },
    verifyEmail: {
      url: '/verify-email'
    }
  },
  commonAPI: {
    state: {
      url: '/lookups/provinces-states/combo'
    },
    city: {
      url: '/lookups/cities/combo'
    },
    skills: {
      url: '/lookups/nurse-certifications/combo'
    },
    language: {
      url: '/lookups/languages/combo'
    },
    ethnicity: {
      url: '/lookups/ethnicities'
    },
    document: {
      url: '/agency/patient/documents'
    },
    profession: {
      url: `/nurse/agency-nurses-list`,
    },
    specialties: {
      url: `/lookups/nurse-skills/combo`,
    },
    employmentTypes: {
      url: `/lookups/pay-rate-types`,
    },
    preferredShiftTimings: {
      url: `/lookups/job-shift-timings/combo`,
    },
    makeAnOffer: {
      url: "/agency/nurse-hire/make-offer",
    },
    gender: {
      url: '/lookups/genders/combo'
    },
    allPersons: {
      url: '/common/users/combo'
    },
    regions: {
      url: '/lookups/regions/combo'
    },
    adminsList: {
      url: '/common/users/combo'
    },
    educationalSubjectsList: {
      url: '/lookups/courses/combo'
    },
    degreesList: {
      url: '/lookups/degrees/combo'
    },
    countriesList: {
      url: '/lookups/countries/combo'
    },
    phoneTypeList: {
      url: "/lookups/phone-types/combo",
    },
    relationshipsList: {
      url: "/lookups/relationships/combo",
    },
    reminderDaysList: {
      url: "/lookups/reminder/combo",
    },
    certificateTypesList: {
      url: "/lookups/nurse-certifications/combo",
    },
    evaluationTypesList: {
      url: "/lookups/nurse-evaluations/combo",
    },
    formTemplateTypesList: {
      url: "/lookups/form-template-types/combo",
    },
    formTemplatesList: {
      url: "/agency/forms/combo",
    },
    weekDays: {
      url: `/lookups/week-days/combo`
    },
  },
  combos: {
    ...combosLive,
    // ...combosSecure,
  },
  lookups: {
    animalTypes: {
      url: '/lookups/animal-types'
    },
    // countries: {
    //     url: '/lookups/animal-types'
    // },
    // designations: {
    //     url: '/lookups/animal-types'
    // },
    // eduInstitutions: {
    //     url: '/lookups/animal-types'
    // },
    eduDegrees: {
      url: '/lookups/degrees'
    },
    eduSubjects: {
      url: '/lookups/courses'
    },
    ethnicities: {
      url: '/lookups/ethnicities'
    },
    genders: {
      url: '/lookups/genders'
    },
    // personalTitles: {// NEEDS TESTING ON API SIDE
    //     url: '/lookups/personal-titles'
    // },
    // jobTitles: {// NEEDS TESTING ON API SIDE
    //     url: '/lookups/job-titles'
    // },
    // occupations: {
    //     url: '/lookups/animal-types'
    // },
    employmentTypes: {
      url: '/lookups/pay-rate-types'
    },
    personalRelationships: {
      url: '/lookups/relationships'
    },
    phoneTypes: {
      url: '/lookups/phone-types'
    },
    // provincesOrStates: {
    //     url: '/lookups/animal-types'
    // },
    spokenLanguages: {// NEEDS TESTING ON API SIDE
      url: '/lookups/languages'
    },
    reminderDaysOptions: {
      url: '/lookups/reminder'
    },
    triageLevels: {
      url: '/lookups/triage-levels'
    },
    nClasses: {
      url: '/lookups/nurse-classes'
    },
    // xxxx: {
    //     url: '/lookups/animal-types'
    // },
    shiftTimings: {
      url: '/lookups/job-shift-timings'
    },
    nCertifications: {
      url: '/lookups/nurse-certifications'
    },
    nEvaluationItems: {
      url: '/lookups/nurse-evaluations'
    },
    patientRegions: {
      url: '/lookups/regions'
    },
    physicianTypes: {
      url: '/lookups/physician-types'
    },
    // xxxx: {
    //     url: '/lookups/animal-types'
    // },
    // xxxx: {
    //     url: '/lookups/animal-types'
    // },
    serviceRequestTypes: {
      url: '/lookups/service-request-types'
    },
    serviceRequestTaskCategories: {
      url: '/lookups/service-request-task-categories'
    },
    oClasses: {
      url: '/lookups/organization-classes'
    },
  },
  forms: {
    createTemplate: {
      url: '/agency/forms'
    },
    formsData: {
      url: '/agency/forms/data'
    },
    forms: {
      url: '/forms'
    },
    nurseFormsData: {
      url: '/forms/data'
    },
  },
  transaction: {
    getAddFundsTempOrderData: {
      url: "/payments/paypal/get-order-temp-data",
    },
    captureAddFundsRequest: {
      url: "/payments/paypal/capture-order",
    },
  },
};
