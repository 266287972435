export const ADMIN_NURSE_LIST = "ADMIN_NURSE_LIST";
export const ADMIN_VERIFIED_NURSE_LIST = "ADMIN_VERIFIED_NURSE_LIST";
export const ADMIN_UNVERIFIED_NURSE_LIST = "ADMIN_UNVERIFIED_NURSE_LIST";
export const ADMIN_WITHDRAWAL_REQUEST_LIST = "ADMIN_WITHDRAWAL_REQUEST_LIST";
export const GET_ADMIN_ALL_VERIFIED_NURSE_LIST = "GET_ADMIN_ALL_VERIFIED_NURSE_LIST";
export const GET_ADMIN_ALL_UNVERIFIED_NURSE_LIST = "GET_ADMIN_ALL_UNVERIFIED_NURSE_LIST";
export const GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST = "GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST";
export const GET_ADMIN_ALL_NURSE_LIST = "GET_ADMIN_ALL_NURSE_LIST";
export const ADMIN_NURSES_SUMMARY = "ADMIN_NURSES_SUMMARY";


export const ADMIN_AGENCIES_SUMMARY = "ADMIN_AGENCIES_SUMMARY";
export const ADMIN_AGENCIES_LIST = "ADMIN_AGENCIES_LIST";
export const GET_ADMIN_ALL_AGENCIES_LIST = "GET_ADMIN_ALL_AGENCIES_LIST";
export const ADMIN_VERIFIED_AGENCIES_LIST = "ADMIN_VERIFIED_AGENCIES_LIST";
export const GET_ADMIN_ALL_VERIFIED_AGENCIES_LIST = "GET_ADMIN_ALL_VERIFIED_AGENCIES_LIST";
export const ADMIN_UNVERIFIED_AGENCIES_LIST = "ADMIN_UNVERIFIED_AGENCIES_LIST";
export const GET_ADMIN_ALL_UNVERIFIED_AGENCIES_LIST = "GET_ADMIN_ALL_UNVERIFIED_AGENCIES_LIST";
