import liveUrls from "./liveUrls";
import comboUrls from "./comboUrls";

export default {
  auth: {
    logout: {
      url: '/logout'
    },
  },
  client: {
    createClient: {
      url: `/admin/patient/info/create`
    },
    profileMinimalInfo: {
      url: `/admin/patient/profile/minimal-info`
    },
    clientList: {
      url: `/admin/patient/list`
    },
    getClientList: {
      url: `/admin/patient/list`
    },
    getClientById: {
      url: `/admin/patient/profile/personal-info`
    },
    clientProfileCareInfo: {
      url: `/admin/patient/profile/careInfo`
    },
    clientProfilePersonalInfoPersonalTitleAll: {
      url: `/lookups/personal-titles/all`
    },
    maritalStatus: {
      url: `/lookups/marital-status/combo`
    },
    setEffectiveEndDate: {
      url: '/admin/patient/profile/careInfo/change-status'
    },
    createNewReferral: {
      url: '/admin/patient/profile/referral'
    },
    preferredCaregiver: {
      url: `/admin/nurse/list/combo`
    },
    postResidentialInfoAddress: {
      url: `/admin/patient/profile/residential-info-address`
    },
    billingInfoAddress: {
      url: `/admin/patient/profile/billing-info-address`
    },
    contactInfoAddress: {
      url: `/admin/patient/profile/contact-info`
    },
    characteristics: {
      url: `/admin/patient/profile/characteristics`
    },
    petInfo: {
      url: `/admin/patient/profile/pets`
    },
    serviceDetails: {
      url: `/admin/service-request`
    },
    getCareList: {
      url: `/admin/service-request`
    },
    getTaskInformation: {
      url: `/admin/patient/task-information`
    },
    dailyNotes: {
      url: `/admin/patient/daily-notes`
    },
    signatureInformation: {
      url: `/admin/patient/signature-information`
    },
    state: {
      url: `/lookups/provinces-states/combo`
    },
    city: {
      url: `/lookups/cities/combo`
    },
    animalTypes: {
      url: `/lookups/animal-types`
    },
    notes: {
      url: `/admin/patient/notes`
    },
    respPartyContact: {
      url: `/admin/patient/information/responsible-party`
    },
    documentList: {
      url: `/admin/patient/profile/document-list`
    },
    healthcareInformation: {
      url: `/admin/patient/healthcare/information`
    },
    healthcareExtraInformation: {
      url: `/admin/patient/healthcare/extra-information`
    },
    healthcareMedication: {
      url: `/admin/patient/healthcare/medication`
    },
    healthcareAllergies: {
      url: `/admin/patient/healthcare/allergies`
    },
    spouse: {
      url: `/admin/patient/profile/spouse`
    },
    jobTitle: {
      url: `/lookups/job-titles`
    },
    patientCharacteristics: {
      url: `/admin/patient/characteristics`
    },
    physicians: {
      url: `/lookups/physicians/combo`
    },
    physiciansTypes: {
      url: `/lookups/physician-types/combo`
    },
    preferredHospital: {
      url: `/lookups/hospitals/combo`
    },
    preferredHomeHealthAgency: {
      url: `/lookups/health-agencies/combo`
    },
    preferredHospiceAgency: {
      url: `/lookups/hospice-agencies/combo`
    },
    weekDays: {
      url: `/lookups/week-days/combo`
    },
    dosageRouteTypes: {
      url: `/lookups/dosage-route-types/combo`
    },
    scheduledTimeOptions: {
      url: `/lookups/scheduled-time-options/combo`
    },
    scheduledDayOptions: {
      url: `/lookups/scheduled-day-options/combo`
    },
    charting: {
      url: `/admin/patient/charting`
    },
    serviceRequestStatus: {
      url: `/lookups/service-request-status/combo`
    },
    activityCompleted: {
      url: `/admin/nurse/activity-completed/list`
    },
    serviceRequestTasks: {
      url: `/admin/tasks`
    },
    taskDetails: {
      url: `/admin/nurse/tasks`
    },
    createSpouse: {
      url: `/admin/patient/profile/spouse`
    },
    referrals: {
      url: `/admin/patient/profile/referrals`
    },
    documents: {
      url: `/admin/patient/documents`
    },
  },
  serviceRequests: {
    createRequest: {
      url: '/admin/service-request'
    },
    agencyMoneyWallet: {
      url: '/admin/money/verify-nurse-payment'
    },
    deleteRequest: {
      url: '/admin/service-request'
    },
    agencyRateAgency: {
      url: '/admin/rate-nurse'
    },
    moneyPayNurse: {
      url: '/admin/money/pay-nurse'
    },
    moneyBankDetails: {
      url: '/nurse/money/bank-details'
    },
    allRequest: {
      url: '/admin/service-request'
    },
    nurseList: {
      url: '/admin/nurse/list/combo'
    },
    assignNurse: {
      url: '/admin/assign-nurses/single'
    },
    clientList: {
      url: '/lookups/genders/combo'
    },
    additionalQuestions: {
      url: "/nurse/additional-questions",
    },
    // agencyServiceRequest: {
    //     url: "/admin/service-request",
    // },
    serviceRequestTask: {
      url: "/admin/nurse/tasks",
    },
    nurseRatingByAgency: {
      url: '/admin/rate-nurse'
    },
    taskDetails: {
      url: `/admin/tasks`
    },
  },
  findNurse: {
    nurseList: {
      url: `/admin/nurse/list`,
    },
    findList: {
      url: `/admin/nurse/list/find`,
    },
    professionList: {
      url: `/lookups/nurse-classes/combo`,
    },
    specialtiesList: {
      url: `/lookups/nurse-skills/combo`,
    },
    stateList: {
      url: `/lookups/provinces-states/combo`,
    },
    employmentTypeList: {
      url: `/nurse/agency-nurses-list`,
    },
    preferredShiftTimingsList: {
      url: `/lookups/job-shift-timings/combo`,
    },
    preferredLanguageList: {
      url: `/lookups/languages/combo`,
    },
    workExperience: {
      url: `/lookups/work-experience-years/combo`
    },
    payRateType: {
      url: `/lookups/pay-rate-types/combo`
    },
  },
  nursesProfile: {
    nurseAgreement: {
      url: "/admin/nurse/agreement",
    },nurseSkills: {
      url: "/lookups/nurse-skills/combo",
    },
    addNurse: {
      url: '/admin/create-nurse'
    },
    nurseList: {
      url: '/admin/nurse/list'
    },
    personalInfo: {
      url: '/admin/nurse/personal-info'
    },
    accountInfo: {
      url: '/admin/nurse/account-info'
    },
    accountDetails: {
      url: '/admin/nurse/account-details'
    },
    nurseClassList: {
      url: '/lookups/nurse-classes'
    },
    payRates: {
      url: '/admin/nurse/pay-rates'
    },
    payRatesHistory: {
      url: '/admin/nurse/pay-rate-history'
    },
    mailingInfo: {
      url: '/admin/nurse/mailing-info'
    },
    contactInfo: {
      url: '/admin/nurse/contact-info'
    },
    workExperience: {
      url: "/admin/nurse/work-experience"
    },
    education: {
      url: '/admin/nurse/education'
    },
    certificationGet: {
      url: '/admin/nurse/document-list'
    },
    nurseDetail: {
      url: '/admin/nurse/info'
    },
    nurseAdditionalQuestions: {
      url: "/admin/nurse/additional-questions",
    },
    emergencyContacts: {
      url: "/admin/nurse/emergency-contact",
    },
    nurseRateAgency: {
      url: '/nurse/rate-agency'
    },
    nurseDocuments: {
      url: "/admin/nurse/documents",
    },
    setEffectiveEndDate: {
      url: '/admin/nurse/account-info/change-status'
    },
    cityList: {
      url: "/lookups/cities/combo",
    },
    // phoneTypeList: {
    //     url: "/lookups/phone-types/combo",
    // },
    certificationsList: {
      url: "/admin/nurse/certifications",
    },
    evaluationsList: {
      url: "/admin/nurse/evaluations",
    },
    otherCertificationsList: {
      url: "/admin/nurse/certifications/other",
    },
  },
  userMessages: {
    userListForChat: {
      url: "/chat/users",
    },
    userChatMessages: {
      url: "/chat/messages",
    },
    acceptDeclineOffer: {
      url: "/nurse/agency-offer/accept-decline-offer",
    },
  },
  transaction: {
    transcationHistory: {
      url: "/nurse/money/transaction-history"
    },
    agencyTransaction: {
      url: "/admin/money/transaction-history"
    },
    subScriptionTransaction: {
      url: "/admin/subscription-history",
    },
    subScriptionHistory: {
      url: "/admin/planSubscriptions/history"
    },
    subscriptionPlan: {
      url: "/common/subscriptions-plans"
    },
    upgradSubscriptionPlan: {
      url: "/common/subscriptions-plans/payment",
    },
    paypalPaymentOrder: {
      url: "/paypal/payment/capture-order",
    },
    addFund: {
      url: "agency/money/add-funds"
    },
    addFundPaymentOrder: {
      url: "/admin/money/add-funds/payment-order"
    },
    transactionHistory: {
      url: "/admin/money/transaction-history"
    },
    agencyWalletDetails: {
      url: "/admin/money/wallet"
    }
  },
  charting: {
    createTask: {
      url: '/nurse/task'
    },
    chartingList: {
      url: '/admin/charting/list'
    },
    taskList: {
      url: '/nurse/task'
    },
    initialAssessment: {
      url: '/admin/patient/charting-data/initial-assessment'
    },
    supervisoryVisits: {
      url: '/admin/patient/charting-data/supervisory-visit'
    },
    dischargeSummaries: {
      url: '/admin/patient/charting-data/discharge-summary'
    },
    carePlans: {
      url: '/admin/patient/charting-data/care-plan'
    },
    chartingData: {
      url: '/admin/patient/charting-data'
    },
    adultAssessment: {
      url: '/admin/patient/adult-assessment'
    },
  },
  reports: {},
  commonAPI: {
    state: {
      url: '/lookups/provinces-states/combo'
    },
    city: {
      url: '/lookups/cities/combo'
    },
    skills: {
      url: '/lookups/nurse-certifications/combo'
    },
    language: {
      url: '/lookups/languages/combo'
    },
    ethnicity: {
      url: '/lookups/ethnicities'
    },
    document: {
      url: '/admin/patient/documents'
    },
    specialties: {
      url: `/lookups/nurse-skills/combo`,
    },
    employmentTypes: {
      url: `/lookups/pay-rate-types`,
    },
    preferredShiftTimings: {
      url: `/lookups/job-shift-timings/combo`,
    },
    makeAnOffer: {
      url: "/admin/nurse-hire/make-offer",
    },
    gender: {
      url: '/lookups/genders/combo'
    },
    allPersons: {
      url: '/common/users/combo'
    },
    regions: {
      url: '/lookups/regions/combo'
    },
    adminsList: {
      url: '/common/users/combo'
    },
    educationalSubjectsList: {
      url: '/lookups/courses/combo'
    },
    degreesList: {
      url: '/lookups/degrees/combo'
    },
    countriesList: {
      url: '/lookups/countries/combo'
    },
    phoneTypeList: {
      url: "/lookups/phone-types/combo",
    },
    relationshipsList: {
      url: "/lookups/relationships/combo",
    },
    reminderDaysList: {
      url: "/lookups/reminder/combo",
    },
    certificateTypesList: {
      url: "/lookups/nurse-certifications/combo",
    },
    evaluationTypesList: {
      url: "/lookups/nurse-evaluations/combo",
    },
    formTemplateTypesList: {
      url: "/lookups/form-template-types/combo",
    },
    formTemplatesList: {
      url: "/admin/forms/combo",
    },
    weekDays: {
      url: `/lookups/week-days/combo`
    },
  },
  jobPosting: {
    postJob: {
      url: '/admin/agency/jobs'
    },
    getJob: {
      url: '/admin/agency/jobs'
    },
    publishJob: {
      url: '/admin/agency/jobs/publish'
    },
    deleteJob: {
      url: '/admin/agency/jobs'
    },
    cancelJob: {
      url: '/admin/agency/jobs/cancel'
    },
    acceptJob: {
      url: '/admin/agency/nurse/bids/accept'
    },
    updateJob: {
      url: '/admin/agency/jobs'
    },
    jobDetails: {
      url: '/admin/agency/jobs'
    }
  },
  alerts: {
    alerts: {
      url: `/admin/alerts`
    }
  },
  agencyProfile: {
    agencyProfile: {
      url: '/admin/agency/profile'
    },
    details: {
      url: 'agency/profile/info'
    },
    address: {
      url: 'agency/profile/address'
    },
    authPerson: {
      url: 'agency/profile/authp'
    },
    logo: {
      url: 'agency/profile/logo'
    },
  },
  admin: {
    nursesSummary: {
      url: "/admin/nurse/list/summary",
    },
    nurseList: {
      url: "/admin/nurse/list",
    },
    // verifiedNurseList: {
    //   url: "/admin/nurse/list?verified=true",
    // },
    // notVerifiedNurseList: {
    //   url: "/admin/nurse/list?verified=false",
    // },
    // unVerifiedNurseList: {
    //   url: "/admin/nurse/list",
    // },
    nurseVerify: {
      url: '/admin/nurse/verify'
    },
    withdrawalRequest: {
      url: '/admin/nurse/withdrawal'
    },
    moneyBankDetails: {
      url: '/admin/nurse/withdrawal/bank-details'
    },
    nurseTerminate: {
      url: '/admin/nurse/terminate'
    },
    agenciesSummary: {
      url: '/admin/agency/list/summary'
    },
    agencyList: {
      url: '/admin/agency/list'
    },
    agencyVerify: {
      url: '/admin/agency/verify'
    },
    agencyTerminate: {
      url: '/admin/agency/terminate'
    },
    jobPreferences: {
      url: '/admin/nurse/job-preferences'
    },
    transactions: {
      url: '/admin/agency/transactions'
    },
    subscriptions: {
      url: '/admin/agency/subscriptions'
    },
    jobs: {
      url: '/admin/agency/jobs'
    }
  },
  forms: {
    createTemplate: {
      url: '/admin/forms'
    },
    formsData: {
      url: '/admin/forms/data'
    },
    forms: {
      url: '/forms'
    },
    nurseFormsData: {
      url: '/forms/data'
    },
  },
  combos: {
    ...liveUrls.combosLive, // always accessible
    ...comboUrls, // secure
    clientsList: {
      url: `/admin/patient/list/combo`
    },
  },
  lookups: {
    animalTypes: {
      url: '/lookups/animal-types'
    },
    // countries: {
    //     url: '/lookups/animal-types'
    // },
    // designations: {
    //     url: '/lookups/animal-types'
    // },
    // eduInstitutions: {
    //     url: '/lookups/animal-types'
    // },
    eduDegrees: {
      url: '/lookups/degrees'
    },
    eduSubjects: {
      url: '/lookups/courses'
    },
    ethnicities: {
      url: '/lookups/ethnicities'
    },
    genders: {
      url: '/lookups/genders'
    },
    // personalTitles: {// NEEDS TESTING ON API SIDE
    //     url: '/lookups/personal-titles'
    // },
    // jobTitles: {// NEEDS TESTING ON API SIDE
    //     url: '/lookups/job-titles'
    // },
    // occupations: {
    //     url: '/lookups/animal-types'
    // },
    employmentTypes: {
      url: '/lookups/pay-rate-types'
    },
    personalRelationships: {
      url: '/lookups/relationships'
    },
    phoneTypes: {
      url: '/lookups/phone-types'
    },
    // provincesOrStates: {
    //     url: '/lookups/animal-types'
    // },
    spokenLanguages: {// NEEDS TESTING ON API SIDE
      url: '/lookups/languages'
    },
    reminderDaysOptions: {
      url: '/lookups/reminder'
    },
    triageLevels: {
      url: '/lookups/triage-levels'
    },
    nClasses: {
      url: '/lookups/nurse-classes'
    },
    // xxxx: {
    //     url: '/lookups/animal-types'
    // },
    shiftTimings: {
      url: '/lookups/job-shift-timings'
    },
    nCertifications: {
      url: '/lookups/nurse-certifications'
    },
    nEvaluationItems: {
      url: '/lookups/nurse-evaluations'
    },
    patientRegions: {
      url: '/lookups/regions'
    },
    physicianTypes: {
      url: '/lookups/physician-types'
    },
    // xxxx: {
    //     url: '/lookups/animal-types'
    // },
    // xxxx: {
    //     url: '/lookups/animal-types'
    // },
    serviceRequestTypes: {
      url: '/lookups/service-request-types'
    },
    serviceRequestTaskCategories: {
      url: '/lookups/service-request-task-categories'
    },
    oClasses: {
      url: '/lookups/organization-classes'
    },
  }
};
