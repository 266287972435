import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import commonReducer from "./helpers/commonReducer";
import serviceRequestReducer from "./Agency/components/ServiceRequest/serviceRequestReducer";
import chartingReducer from "./helpers/chartingReducer";
// import nursesProfileReducer from "./helpers/NursesReducer";
import nurseListingReducer from "./Agency/components/Nurses/nurseReducer";
import messageReducer from "./Nurses/components/Messages/messageReducer";
import transactionReducer from "./Agency/components/Dashboard/agecnyTransaction/transactionReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import clientReducer from "./helpers/clientReducer";
import authReducer from "./helpers/authReducer";
import alertsReducer from "./helpers/alertsReducer";
import agencyProfileReducer from "./helpers/agencyProfileReducer";
import postJobsReducer from "./helpers/postJobsReducer";
import dynamicFormReducer from "./helpers/dynamicFormReducer";
import adminReducer from "./Admin/redux/reducers/AdminReducer";

const rootreducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  serviceRequest: serviceRequestReducer,
  nurseListing: nurseListingReducer,
  messages: messageReducer,
  charting: chartingReducer,
  // nursesProfile : nursesProfileReducer,
  client: clientReducer,
  transaction: transactionReducer,
  form: formReducer,
  toastr: toastrReducer, // <- Mounted at toastr.
  alerts: alertsReducer,
  agencyProfile: agencyProfileReducer,
  postJobs: postJobsReducer,
  dynamicForm: dynamicFormReducer,
  admin: adminReducer,
});

export default rootreducer;
