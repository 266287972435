import { errorInterceptor } from "./error-interceptor";

const debug = process.env.NODE_ENV !== 'production';
// console.log(`This ap is running in ${process.env.NODE_ENV} mode`);

export const setResponseInterceptor = (client) => {
  client.interceptors.response.use(
    function (response) {
      // debug &&
      //   console.log({
      //     request: response.config,
      //     response: response.data,
      //   });
      return response.data;
    },
    function (error) {
      return errorInterceptor(debug, error);
    }
  );
};
