export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID';
export const GET_CARE_INFO_BY_ID = 'GET_CARE_INFO_BY_ID';
export const GET_PERSONAL_INFO_ALL_TITLE = 'GET_PERSONAL_INFO_ALL_TITLE';
export const GET_PREFERRED_CAREGIVER = 'GET_PREFERRED_CAREGIVER';
export const GET_RESIDENTIAL_INFO_ADDRESSES = 'GET_RESIDENTIAL_INFO_ADDRESSES';
export const GET_PET_INFO = 'GET_PET_INFO';
export const GET_CONTACT_INFO_ADDRESS = 'GET_CONTACT_INFO_ADDRESS';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const GET_BILLING_INFO_ADDRESSES = 'GET_BILLING_INFO_ADDRESSES';
export const SET_PERSONAL_PROFILE_AGE = 'SET_PERSONAL_PROFILE_AGE';
export const ADD_PET = 'ADD_PET';
export const GET_RESP_PARTY_CONTACTS = 'GET_RESP_PARTY_CONTACTS';
export const ADD_RESP_PART_CONTACT = 'ADD_RESP_PART_CONTACT';
export const GET_HEALTHCARE_EXTRA_INFO = 'GET_HEALTHCARE_EXTRA_INFO';
export const ADD_HEALTHCARE_DIAGNOSIS = 'ADD_HEALTHCARE_DIAGNOSIS';
