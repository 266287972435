import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./store";
import ReduxToastr from 'react-redux-toastr'
import "./index.css";
import App from "./App";
import "./styles.scss";
import {localStoragePersister, queryClient} from "./utils/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import ReactErrorBoundary from "./ReactErrorBoundary";

const store = configureStore({}, {});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PersistQueryClientProvider client={queryClient} persistOptions={{persister: localStoragePersister}} contextSharing>
    <Provider store={store}>
      <ReactErrorBoundary>
        <App />
      </ReactErrorBoundary>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick/>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </PersistQueryClientProvider>
);
