import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { BrowserRouter } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Nurses/assets/sass/style.css";
import "./Nurses/assets/css/main.css";
import "./Nurses/assets/css/stylesheet.css";
import "./Nurses/assets/css/themestyle.css";
import "./Nurses/assets/agency/css/stylesheet.css";
import "./Nurses/assets/css/responsive.css";
import "./Nurses/assets/css/jquery.beefup.css";
import "./Nurses/assets/css/customeCss.css";
import "./Agency/assets/css/service-new.css";
import "./Nurses/assets/css/dynamicTable.css";
import "./Admin/assets/css/responsive.css";
import "./common.css";
import AllRoutes from "./AllRoutes";
import "react-datepicker/dist/react-datepicker.css";
import {Dialog} from "@mui/material";
import classes from "./app.module.css"
import {
  useEthnicitiesList,
  useGendersList,
  useLanguagesList,
  useOtherLanguagesList,
  usePersonalTitlesList,
  useProfessionsList,
  useProvincesOrStatesList
} from "./services/commonNew";

const App = ({ loading }) => {
  const gendersQuery = useGendersList();
  const provincesOrStatesQuery = useProvincesOrStatesList();
  const professionsQuery = useProfessionsList();
  const languagesQuery = useLanguagesList();
  const otherLanguages = useOtherLanguagesList();
  const ethnicitiesQuery = useEthnicitiesList();
  const personalTitlesQuery = usePersonalTitlesList();

  useEffect(() => {
    // load all static data from API calls only ONCE
    /// IIFE
    (async () => {
      (await gendersQuery).refetch();
      (await provincesOrStatesQuery).refetch();
      (await professionsQuery).refetch();
      (await languagesQuery).refetch();
      (await otherLanguages).refetch();
      (await ethnicitiesQuery).refetch();
      (await personalTitlesQuery).refetch();
    })();
  }, [])

  return (
    <>
      <BrowserRouter>
        <AllRoutes />
        <Dialog
          className={classes.loaderDialog}
          open={loading}
          fullWidth={true}
        >
          <div style={{height:'100px'}}>
            <CircularProgress color="inherit"/>
          </div>
        </Dialog>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = (state) => {
  const { common } = state;
  return {
    loading: common.loading || false,
  };
};

export default connect(mapStateToProps)(App);
