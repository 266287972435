const numberHelperFunctions = {

  convertToIntegerString(value, defaultValue = '') {
    if (value != null) {
      if (typeof value === 'string') {
        return String(+value);
      }
      if (typeof value === 'number') {
        return String(value);
      }
    }
    return typeof defaultValue === 'string' ? defaultValue : String(defaultValue);
  },

  convertToFloatString(value, defaultValue = '') {
    if (value != null) {
      if (typeof value === 'string') {
        return String(parseFloat(value));
      }
      if (typeof value === 'number') {
        return String(value);
      }
    }
    return defaultValue;
  },

  formatNumber(amount, fractionDigits = 2) {
    if (isNaN(amount)) return '';
    return new Intl.NumberFormat('en-US', {style: 'decimal', useGrouping: true, minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits}).format(amount);
  },

  nonZeroPositiveIntegerToString(val) {
    val = val != null ? val : 0;
    return val > 0 ? String(val) : "";
  },

  nullIfEmpty(value, zeroThenNull = false) {
    if (value != null) {
      if (typeof value === 'number' && value !== 0) return value;
      if (typeof value === 'string') {
        if (value.length === 0) {
          return null;
        } else {
          if (!(zeroThenNull && (value === 0 || value === '0'))) {
            return +value;
          }
        }
      }
    }
    return null;
  },

  undefinedIfEmpty(value, zeroThenUndefined = false) {
    if (value != null) {
      if (typeof value === 'number' && value !== 0) return value;
      if (typeof value === 'string') {
        if (value.length === 0) {
          return undefined;
        } else {
          if (!(zeroThenUndefined && (value === 0 || value === '0'))) {
            return +value;
          }
        }
      }
    }
    return undefined;
  },

  nullIfEmptyFloat(value) {
    if (value != null) {
      if (typeof value === 'string') {
        return value.length === 0 ? null : parseFloat(value);
      }
      return value;
    }
    return null;
  },

  round(number, decimalPlaces = 2) {
    const factor1 = Math.pow(10, decimalPlaces + 1);
    const value = Math.round((number + Number.EPSILON) * factor1) / factor1;
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(value * factor) / factor;
  },


};

export default numberHelperFunctions;
