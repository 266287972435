
// accessible after login
export default {
  serviceRequestTypesList: {
    url: '/lookups/service-request-types/combo'
  },
  frequencyList: {
    url: '/lookups/week-days/combo'
  },
  serviceRequestTasksList: {
    url: '/lookups/service-request-tasks/combo'
  },
  maritalStatusList: {
    url: `/lookups/marital-status/combo`
  },
  regionsList: {
    url: '/lookups/regions/combo'
  },
  adminsList: {
    url: '/common/users/combo'
  },
  allPersonsList: {
    url: '/common/users/combo'
  },
  degreesList: {
    url: '/lookups/degrees/combo'
  },
  coursesList: {
    url: '/lookups/courses/combo'
  },
  phoneTypeList: {
    url: "/lookups/phone-types/combo",
  },
  relationshipsList: {
    url: "/lookups/relationships/combo",
  },
  reminderDaysList: {
    url: "/lookups/reminder/combo",
  },
  certificateTypesList: {
    url: "/lookups/nurse-certifications/combo",
  },
  evaluationTypesList: {
    url: "/lookups/nurse-evaluations/combo",
  },
  formTemplateTypesList: {
    url: "/lookups/form-template-types/combo",
  },
  formTemplatesList: {
    url: "/agency/forms/combo",
  },
  animalTypesList: {
    url: `/lookups/animal-types/combo`
  },
  triageLevelsList: {
    url: '/lookups/triage-levels/combo'
  },
  physicianTypesList: {
    url: `/lookups/physician-types/combo`
  },
  employmentTypesList: {
    url: `/lookups/pay-rate-types/combo`
  },
  workExperienceYearsList: {
    url: `/lookups/work-experience-years/combo`
  },
  shiftTimingsList: {
    url: `/lookups/job-shift-timings/combo`,
  },
};
