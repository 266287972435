import {AxiosError} from "axios";
import storageManager from "../helpers/storageManager";

export const errorInterceptor = (debug, error) => {
  console.error('is-axios-error:', error instanceof AxiosError);
  // console.error('error response:', error.response);
  if (error.response != null) {
    const errorData = error.response.data;
    const errorMessage = errorData?.message || 'NIL';
    const errorStatusCode = error.response.status;

    // console.error('debug:', debug);
    // The request was made, but the server responded with an error status code
    if (debug) {
      console.log({
        request: JSON.stringify(error.toJSON()?.config),
        error: JSON.stringify(errorData),
      });
    } else {
      console.error('HTTP Error:', errorStatusCode);
      console.error('Error Response:', errorData);
    }

    if (errorStatusCode === 400) {
      if (errorMessage.indexOf('account is inactive') > -1 || errorMessage.indexOf('account is blocked') > -1) {
        return Promise.resolve(errorData);
      }
    }

    // console.error('url:', `${window.location.protocol}//${window.location.host}`);
    // console.error('host:', window.location);
    // console.log('1', errorStatusCode)
    // console.log('2', errorData)
    // console.log('3', errorMessage)
    if (errorStatusCode === 401 || (errorStatusCode === 406 && errorData != null && errorMessage.indexOf('auth header absent') > -1)) {
      storageManager.clear();
      window.location.href = `${window.location.protocol}//${window.location.host}`;
    }

    if (errorStatusCode === 404) {
      if (errorMessage === 'Data Not Found') {
        return Promise.resolve(errorData);
        // return errorData;
      }
    }

    if (errorStatusCode === 406) {
      if (errorMessage === 'INVALID_INPUT') {
        return Promise.reject(errorData);
      }
    }

    return Promise.reject({
      status: errorStatusCode,
      message: errorMessage,
    });
  }

  if (error.request) {
    // The request was made, but no response was received
    console.error('No response received:', error.request);
    return Promise.reject(error.request);
  }

  // Something happened in setting up the request that triggered an error
  console.error('Error:', error.message);
  return Promise.reject({
    status: 444,
    message: "Some error occurred. Please try again after few minutes.",
  });
};
