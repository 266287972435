// import * as clientAction from "../Agency/components/Client/clientActionType";
import {parseTokenAndGetData} from "./apiMethods";

const authReducer = (state = {
    authData: parseTokenAndGetData().data,
    agencyId: 0,
}, action) => {
    switch (action.type) {
        case `AUTH_${action.meta?.type}`:
            return {
                ...state,
                agencyId: action?.payload?.agencyId || 0,
                [action.name]: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;
