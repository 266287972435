import React, {useEffect} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {isLoggedIn, redirectOrLogout} from "../helpers/apiMethods";
import {useDispatch} from "react-redux";

const tabData = [
  {
    pageName: "Dashboard",
    add: "/nurses-dashboard",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 10H7C7.55 10 8 9.55 8 9V1C8 0.45 7.55 0 7 0H1C0.45 0 0 0.45 0 1V9C0 9.55 0.45 10 1 10ZM1 18H7C7.55 18 8 17.55 8 17V13C8 12.45 7.55 12 7 12H1C0.45 12 0 12.45 0 13V17C0 17.55 0.45 18 1 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 1V5C10 5.55 10.45 6 11 6H17C17.55 6 18 5.55 18 5V1C18 0.45 17.55 0 17 0H11C10.45 0 10 0.45 10 1Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: " Service Requests",
    add: "/nurses-servicerequest/allrequest",
    icon: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16V11H4V12H6V11H14V12H16V11H20V16H0ZM0 6C0 5.45 0.196 4.979 0.588 4.587C0.98 4.195 1.45067 3.99934 2 4H5V2C5 1.45 5.196 0.979002 5.588 0.587002C5.98 0.195002 6.45067 -0.000664969 7 1.69779e-06H13C13.55 1.69779e-06 14.021 0.196001 14.413 0.588001C14.805 0.980001 15.0007 1.45067 15 2V4H18C18.55 4 19.021 4.196 19.413 4.588C19.805 4.98 20.0007 5.45067 20 6V10H16V8H14V10H6V8H4V10H0V6ZM7 4H13V2H7V4Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [
      {
        innertab_pageName: "All Request",
        add: "/nurses-servicerequest/allrequest",
      },
      {
        innertab_pageName: "Pending Request",
        add: "/nurses-servicerequest/pendingrequest",
      },
      {
        innertab_pageName: "Completed Request",
        add: "/nurses-servicerequest/completedrequest",
      },
    ],
  },
  {
    pageName: "Job List",
    add: "/nurses-joblist/alljobs",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.5V6H12V7.5H6ZM0 3.75C0 2.75544 0.395088 1.80161 1.09835 1.09835C1.80161 0.395088 2.75544 0 3.75 0H14.25C15.2446 0 16.1984 0.395088 16.9016 1.09835C17.6049 1.80161 18 2.75544 18 3.75V14.25C18 15.2446 17.6049 16.1984 16.9016 16.9016C16.1984 17.6049 15.2446 18 14.25 18H3.75C2.75544 18 1.80161 17.6049 1.09835 16.9016C0.395088 16.1984 0 15.2446 0 14.25V3.75ZM5.25 4.5C5.05109 4.5 4.86032 4.57902 4.71967 4.71967C4.57902 4.86032 4.5 5.05109 4.5 5.25V8.25C4.5 8.44891 4.57902 8.63968 4.71967 8.78033C4.86032 8.92098 5.05109 9 5.25 9H12.75C12.9489 9 13.1397 8.92098 13.2803 8.78033C13.421 8.63968 13.5 8.44891 13.5 8.25V5.25C13.5 5.05109 13.421 4.86032 13.2803 4.71967C13.1397 4.57902 12.9489 4.5 12.75 4.5H5.25Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [
      {
        innertab_pageName: "All Jobs",
        add: "/nurses-joblist/alljobs",
      },
      {
        innertab_pageName: "All Bids",
        add: "/nurses-joblist/allbids",
      },
      {
        innertab_pageName: "Heat Map",
        add: "/nurses-joblist/heatmap",
      },
    ],
  },
  {
    pageName: "Schedule",
    add: "/nurses-schedule/calender",
    icon: (
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_659_3924"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="19"
        >
          <path
            d="M20 3H2C1.44772 3 1 3.44772 1 4V17C1 17.5523 1.44772 18 2 18H20C20.5523 18 21 17.5523 21 17V4C21 3.44772 20.5523 3 20 3Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 1V5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M11.5 9.5H6M16 13.5H6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M16 1V5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </mask>
        <g mask="url(#mask0_659_3924)">
          <path d="M-1 -2H23V22H-1V-2Z" fill="#9E9E9E" />
        </g>
      </svg>
    ),
    innerTab: [
      {
        innertab_pageName: "Calendar",
        add: "/nurses-schedule/calender",
      },
      {
        innertab_pageName: "Scheduled Outlook",
        add: "/nurses-schedule/scheduledoutlook",
      },
      {
        innertab_pageName: "Task",
        add: "/nurses-schedule/task",
      },
    ],
  },
  {
    pageName: "Open Visits",
    add: "/nurses-openvisit",
    icon: (
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0ZM8 14H3V12H8V14ZM8 10H3V8H8V10ZM8 6H3V4H8V6ZM12.82 12L10 9.16L11.41 7.75L12.82 9.17L15.99 6L17.41 7.42L12.82 12Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Earnings",
    add: "/nurses-earnings",
    icon: (
      <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.414 8.414C15 7.828 15 6.886 15 5C15 3.114 15 2.172 14.414 1.586M14.414 8.414C13.828 9 12.886 9 11 9H7C5.114 9 4.172 9 3.586 8.414M14.414 1.586C13.828 1 12.886 1 11 1H7C5.114 1 4.172 1 3.586 1.586M3.586 1.586C3 2.172 3 3.114 3 5C3 6.886 3 7.828 3.586 8.414M10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6C8.73478 6 8.48043 5.89464 8.29289 5.70711C8.10536 5.51957 8 5.26522 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5Z"
          fill="#9E9E9E"
        />
        <path
          d="M14.414 8.414C15 7.828 15 6.886 15 5C15 3.114 15 2.172 14.414 1.586C13.828 1 12.886 1 11 1H7C5.114 1 4.172 1 3.586 1.586C3 2.172 3 3.114 3 5C3 6.886 3 7.828 3.586 8.414C4.172 9 5.114 9 7 9H11C12.886 9 13.828 9 14.414 8.414ZM10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6C8.73478 6 8.48043 5.89464 8.29289 5.70711C8.10536 5.51957 8 5.26522 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5Z"
          stroke="#9E9E9E"
          strokeWidth="1.5"
        />
        <path
          d="M15 4C14.2044 4 13.4413 3.68393 12.8787 3.12132C12.3161 2.55871 12 1.79565 12 1M15 6C14.2044 6 13.4413 6.31607 12.8787 6.87868C12.3161 7.44129 12 8.20435 12 9M3 4C3.79565 4 4.55871 3.68393 5.12132 3.12132C5.68393 2.55871 6 1.79565 6 1M3 6C3.79565 6 4.55871 6.31607 5.12132 6.87868C5.68393 7.44129 6 8.20435 6 9M1 19.388H3.26C4.27 19.388 5.293 19.494 6.276 19.696C8.031 20.0565 9.83676 20.0965 11.606 19.814M10.676 16.517C10.796 16.503 10.911 16.487 11.021 16.47C11.932 16.325 12.697 15.837 13.397 15.308L15.205 13.943C15.5275 13.7084 15.9161 13.582 16.315 13.582C16.7139 13.582 17.1025 13.7084 17.425 13.943C17.998 14.376 18.174 15.089 17.811 15.671C17.388 16.349 16.792 17.216 16.22 17.746M10.676 16.517C10.6394 16.5212 10.6027 16.5252 10.566 16.529M10.676 16.517C10.8361 16.4737 10.9828 16.3912 11.103 16.277C11.2537 16.1466 11.3767 15.9874 11.4649 15.8087C11.553 15.63 11.6045 15.4355 11.6163 15.2366C11.628 15.0377 11.5998 14.8384 11.5333 14.6506C11.4668 14.4628 11.3633 14.2902 11.229 14.143C11.0987 13.9983 10.9469 13.8746 10.779 13.776C7.982 12.107 3.629 13.378 1 15.243M10.676 16.517C10.6399 16.5249 10.603 16.5289 10.566 16.529M10.566 16.529C9.96292 16.5896 9.35534 16.5909 8.752 16.533"
          stroke="#9E9E9E"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Alerts",
    add: "/nurses-alerts",
    icon: (
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.503 0C14.4421 0 13.4247 0.421427 12.6746 1.17157C11.9244 1.92172 11.503 2.93913 11.503 4V7L10.553 7.8C10.246 8.06 10.093 8.19 10.046 8.291C9.99074 8.41094 9.98517 8.54787 10.0305 8.67189C10.0759 8.79592 10.1684 8.89698 10.288 8.953C10.388 9 10.59 9 10.992 9H20.012C20.415 9 20.616 9 20.717 8.953C20.8366 8.89698 20.9291 8.79592 20.9745 8.67189C21.0198 8.54787 21.0143 8.41094 20.959 8.291C20.912 8.19 20.759 8.06 20.451 7.801L19.503 7V4C19.503 2.93913 19.0816 1.92172 18.3314 1.17157C17.5813 0.421427 16.5639 0 15.503 0ZM17.003 10C16.784 10.583 16.195 11 15.503 11C14.81 11 14.221 10.583 14.003 10H17.003ZM20 15.75V10H18.067L17.935 10.35C17.565 11.339 16.592 12 15.499 12C15.1081 12.0018 14.7217 11.9163 14.3681 11.7497C14.0145 11.5831 13.7025 11.3396 13.455 11.037L10.348 12.664C10.2406 12.7202 10.1212 12.7496 10 12.7496C9.87879 12.7496 9.75938 12.7202 9.652 12.664L0 7.608V15.75L0.00500011 15.934C0.0519987 16.7628 0.414367 17.5422 1.01777 18.1123C1.62117 18.6824 2.41986 19.0001 3.25 19H16.75L16.934 18.995C17.7628 18.948 18.5422 18.5856 19.1123 17.9822C19.6824 17.3788 20.0001 16.5801 20 15.75ZM10 11.153L12.202 10H10.966C10.785 10 10.611 10 10.471 9.993C10.2599 9.98809 10.0517 9.94224 9.858 9.858C9.49994 9.68974 9.22285 9.38684 9.08706 9.01525C8.95127 8.64366 8.96778 8.23347 9.133 7.874C9.22657 7.68491 9.35597 7.51578 9.514 7.376C9.616 7.28 9.749 7.168 9.888 7.051L10.498 6.535V4C10.498 3.658 10.533 3.323 10.598 3H3.25L3.064 3.005C2.29168 3.04919 1.56045 3.36741 1.00175 3.90246C0.44305 4.43752 0.0935234 5.15431 0.016 5.924L10 11.154V11.153Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Reports",
    add: "/nurses-reports/attributereport",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 8L14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8ZM9 19H7V10H9V19ZM13 19H11V13H13V19ZM17 19H15V16H17V19ZM14 9H13V4L18 9H14Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Messages",
    add: "/nurses-messages",
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_659_4922"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="22"
        >
          <path
            d="M21 11C21 16.523 16.523 21 11 21H1V11C1 5.477 5.477 1 11 1C16.523 1 21 5.477 21 11Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 8H15M6 12H15M6 16H11"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_659_4922)">
          <path d="M-1 -1H23V23H-1V-1Z" fill="#9E9E9E" />
        </g>
      </svg>
    ),
    innerTab: [],
  },
];

const NursesSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function splitPath(pathname, index) {
    if (pathname[0] === '/')
      pathname = pathname.substring(1);
    return pathname.split('/')[index];
  }

  useEffect(() => {
    if (!isLoggedIn()) {
      redirectOrLogout(dispatch, navigate);
    }
  }, []);

  return (
    <div className="app-sidebar sidebar-shadow ">
      <div className="scrollbar-sidebar">
        <div className="app-sidebar__inner">
          {/* <!-- Side bar for desktop/mobile --> */}
          <ul className="vertical-nav-menu">
            {tabData.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${splitPath(location.pathname, 1)?.includes(splitPath(item.add, 1))
                    ? "mm-active"
                    : ""
                  }`}
                >
                  <NavLink
                    to={item.add}
                    className={`${splitPath(location.pathname, 1)?.includes(splitPath(item.add, 1))
                      ? "pr-mm"
                      : ""
                    } text-decoration-none`}
                  >
                    {item.icon}
                    {item.pageName}
                    {item.innerTab.length !== 0 && (
                      <i className="metismenu-state-icon pe-7s-angle-down caret-left" onClick={(e) => {
                        const targetElement = e.target;
                        const isActive = targetElement.parentElement.classList.contains('active');
                        // alert(isActive)
                        if (isActive) {
                          targetElement.parentElement.classList.remove('active');
                          targetElement.parentElement.classList.remove('pr-mm');
                          targetElement.parentElement.parentElement.classList.remove('mm-active');
                          targetElement.parentElement.nextElementSibling.classList.remove('mm-showmm-collapse');
                          // targetElement.parentElement.nextElementSibling.classList.add('mm-collapse');
                          targetElement.parentElement.nextElementSibling.classList.remove('mm-show');
                        } else {
                          // targetElement.parentElement.nextElementSibling.classList.remove('mm-collapse');
                          targetElement.parentElement.nextElementSibling.classList.add('mm-show');
                          targetElement.parentElement.nextElementSibling.classList.add('mm-showmm-collapse');
                          targetElement.parentElement.parentElement.classList.add('mm-active');
                          targetElement.parentElement.classList.add('pr-mm');
                          targetElement.parentElement.classList.add('active');
                        }
                      }}></i>
                    )}
                  </NavLink>
                  {item.innerTab.length !== 0 ? (
                    <ul
                      className={`${splitPath(location.pathname, 1)?.includes(splitPath(item.add, 1))
                        ? "mm-show "
                        : ""
                      }mm-collapse`}
                    >
                      {item.innerTab.map((innerTabitem, innerTabindex) => {
                        return (
                          <li key={innerTabindex} className={`${splitPath(location.pathname, 2)?.includes(splitPath(innerTabitem.add, 2))
                            ? "mm-active "
                            : ""
                          }`}>
                            <NavLink
                              to={innerTabitem.add}
                              className={`${splitPath(location.pathname, 2)?.includes(splitPath(innerTabitem.add, 2))
                                ? "text-decoration-underline "
                                : "text-decoration-none"
                              }`}
                            >
                              <i className="metismenu-icon"></i>
                              {innerTabitem.innertab_pageName}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NursesSideBar;
