import * as serviceRequest from './serviceActionTypes';
import {pageLimit} from "../../../appConstants";

const initialState = {
    allRequests: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    scheduledRequests: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    activeRequests: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    pendingRequests: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    completedRequests: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    missedRequests: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    countedIncompleteVisits: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    postedJobs: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    postedJobsActive: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    postedJobsAccepted: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    initialAssessments: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    supervisoryVisits: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    dischargeSummaries: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
    carePlans: {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []},
}

const serviceRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case serviceRequest.ALL_SERVICE_REQUESTS_LIST:
            return {
                ...state,
                allRequests: action.allRequestData
            }
        case serviceRequest.GET_INITIAL_ASSESSMENTS:
            return {
                ...state,
                initialAssessments: action.initialAssessments
            }
        case serviceRequest.GET_SUPERVISORY_VISITS:
            return {
                ...state,
                supervisoryVisits: action.supervisoryVisits || initialState.supervisoryVisits
            }
        case serviceRequest.GET_DISCHARGE_SUMMARIES:
            return {
                ...state,
                dischargeSummaries: action.dischargeSummaries || initialState.dischargeSummaries
            }
        case serviceRequest.GET_CARE_PLANS:
            return {
                ...state,
                carePlans: action.carePlans || initialState.carePlans
            }
        case serviceRequest.SCHEDULED_SERVICE_REQUESTS_LIST:
            return {
                ...state,
                scheduledRequests: action.scheduledRequestData
            }
        case serviceRequest.ACTIVE_SERVICE_REQUESTS_LIST:
            return {
                ...state,
                activeRequests: action.activeData
            }
        case serviceRequest.PENDING_SERVICE_REQUESTS_LIST:
            return {
                ...state,
                pendingRequests: action.pendingData
            }
        case serviceRequest.COMPLETED_SERVICE_REQUESTS_LIST:
            return {
                ...state,
                completedRequests: action.completedData
            }
        case serviceRequest.MISSED_SERVICE_REQUESTS_LIST:
            return {
                ...state,
                missedRequests: action.missedData
            }
        case serviceRequest.INCOMPLETE_VISITS_LIST:
            return {
                ...state,
                countedIncompleteVisits: action.incompleteVisitsData
            }
        case serviceRequest.POSTED_JOBS:
            return {
                ...state,
                postedJobs: action.postedJobsData
            }
        case serviceRequest.POSTED_JOBS_ACTIVE:
            return {
                ...state,
                postedJobsActive: action.postedJobsActiveData
            }
        case serviceRequest.POSTED_JOBS_ACCEPTED:
            return {
                ...state,
                postedJobsAccepted: action.postedJobsAcceptedData
            }
        case serviceRequest.POSTED_JOBS_EXPIRED:
            return {
                ...state,
                postedJobsExpired: action.postedJobsExpiredData
            }
        case serviceRequest.POSTED_JOBS_IN_PROGRESS:
            return {
                ...state,
                postedJobsInProgress: action.postedJobsInProgressData
            }
        case `ADD_SERVICE_REQUEST_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state
    }
}

export default serviceRequestReducer;
