// queryClient.js
import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const defaultQueryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false, // Disables automatic re-fetching when browser window is focused
  refetchOnReconnect: false,
  // cacheTime: 300000,
  // staleTime: 0,
  gcTime: 1000 * 60 * 60 * 24, // 24 hours
  retry: false,
  // retryDelay: 500,
  retryOnMount: false,
  // refetchInterval: 5000,
  refetchIntervalInBackground: false,
  // enabled: false,
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: defaultQueryOptions,
  },
});

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

// persistQueryClient({
//   queryClient: client,
//   persister: localStoragePersister,
// });

