import * as messageAction from './messageActionTypes';
import {parseTokenAndGetData} from "../../../helpers/apiMethods";

const initialState = {
    messageList: {},
    userListForChat: [],
    userChatMessages: [],
    unReadChatMessagesIds: [],
    acceptDeclineOffer: {},
    makeAnOffer: {}
}

const messageReducer = (state = initialState, action) => {
    const decodedTokenData = parseTokenAndGetData().data;
    switch (action.type) {
        case messageAction.MESSAGE_LIST:
            return {
                ...state,
                messageList: action.payload,
                userListForChat: {}
            }
        case messageAction.USER_MESSAGES_LIST:
            const sortedAndFilteredPayload = [...action.payload]
                // .sort((a, b) => new Date(b.lastChatMessageCreatedAt) - new Date(a.lastChatMessageCreatedAt));
                // .filter(obj => Number(obj?.id) !== loginUserId)
            ;
            return {
                ...state,
                userListForChat: sortedAndFilteredPayload
            }
        case messageAction.USER_READ_MESSAGES:
            const user = state.userListForChat.find((obj) => obj?.id?.toString() === action.payload?.id?.toString());
            if (user) {
                user.totalUnreadChatMessages = 0;
            }
            return {
                ...state,
                userListForChat: [...state.userListForChat]
            }
        case messageAction.NEW_MESSAGE_FOR_UNSELECTED_USER:
            const userMsg = state.userListForChat.find((obj) => obj?.id?.toString() === action.payload?.senderId?.toString());
            if (userMsg) {
                userMsg.lastChatMessage = action.payload.text;
                ++userMsg.totalUnreadChatMessages;
                userMsg.lastChatMessageCreatedAt = action.payload.createdAt;
            }
            return {
                ...state,
                userListForChat: [...state.userListForChat]
            }
        case messageAction.USER_CHAT_MESSAGES_LIST:
            let userMessageFiltered = [];
            console.log('action.payload', action.payload)
            if (action.payload) {
                userMessageFiltered = action.payload?.filter((obj) => (!obj.forReceiverOnly && !obj.forSenderOnly) || (obj.senderId === decodedTokenData.chatId && obj.forSenderOnly) || (obj.receiverId === decodedTokenData.chatId && obj.forReceiverOnly))
            }
            return {
                ...state,
                userChatMessages: Array.isArray(userMessageFiltered)
                    ? userMessageFiltered
                    : [...state.userChatMessages, userMessageFiltered]
            }
        case messageAction.ADD_SAVED_CHAT_MESSAGE:
            let savedUserMessage = action.payload;
            // console.log('action.payload', savedUserMessage);
            return {
                ...state,
                userChatMessages: [...state.userChatMessages, savedUserMessage],
            }
        case messageAction.UNREAD_CHAT_MESSAGES_IDS:
            return {
                ...state,
                unReadChatMessagesIds: [...state.unReadChatMessagesIds, action.payload]
            }

        default:
            return state
    }
}

export default messageReducer;
