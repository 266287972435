export const NURSE_LIST = "NURSE_LIST";
export const NURSE_PROFILE = "NURSE_PROFILE";
export const NURSE_ACCOUNT_DETAILS = "NURSE_ACCOUNT_DETAILS";
export const NURSE_MAILING_INFO = "NURSE_MAILING_INFO";
export const NURSE_ADDITIONAL_QUESTIONS = "NURSE_ADDITIONAL_QUESTIONS";
export const NURSE_WORK_EXPERIENCE = "NURSE_WORK_EXPERIENCE";
export const NURSE_EMERGENCY_CONTACTS = "NURSE_EMERGENCY_CONTACTS";
export const NURSE_DETAIL = "NURSE_DETAIL";
export const NURSE_ADD_CONTACTS = "NURSE_ADD_CONTACTS";
export const GET_CITY_LIST = "GET_CITY_LIST";
// export const GET_PHONE_TYPE_LIST = "GET_PHONE_TYPE_LIST";
export const NURSE_ADD_WORK_EXPERIENCE = "NURSE_ADD_WORK_EXPERIENCE";
export const NURSE_ADD_EDUCATION = "NURSE_ADD_EDUCATION";
export const NURSE_EDUCATION = "NURSE_EDUCATION";
export const NURSE_CERTIFICATIONS = "NURSE_CERTIFICATIONS";
export const NURSE_EVALUATIONS = "NURSE_EVALUATIONS";
export const NURSE_OTHER_CERTIFICATIONS = "NURSE_OTHER_CERTIFICATIONS";
export const GET_HIRED_NURSES = "GET_HIRED_NURSES";
export const NURSE_ADD_EMERGENCY_CONTACTS = "NURSE_ADD_EMERGENCY_CONTACTS";
export const PHONE_TYPES = "PHONE_TYPES";
export const NURSE_ADD_CERTIFICATION = "NURSE_ADD_CERTIFICATION";
export const NURSE_ADD_EVALUATION = "NURSE_ADD_EVALUATION";
export const NURSE_ADD_OTHER_CERTIFICATION = "NURSE_ADD_OTHER_CERTIFICATION";
