import {generateUniqueId} from "./apiMethods";

const dynamicFormReducer = (state = {
    dynamicTabsList: [],
    selectedTab: 1,
    selectedFormTabIndex: 0,
    tabTitle: [{title: 'Title', tabIndex: -1, id: generateUniqueId()}]
}, action) => {
    switch (action.type) {
        case `ADD_DYNAMIC_FORM_${action.meta?.type}`:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state;
    }
};

export default dynamicFormReducer;
