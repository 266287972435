import api from "./interceptor";
import storageManager from "./storageManager";
import {apiBaseUrl, defaultErrorMessage, paypalDeductionPercentage} from "../appConstants";
import getApiUrl from "./apiURLs";
import numberHelperFunctions from "./number.helpers";

function makeCompatibleComboOptions(responseItems) {
    return responseItems.map(function (item) {
        var obj = {label: "", value: ""};
        obj.label = item.name;
        obj.value = String(item.id);
        return obj;
    });
}

export function populateDropdown(url) {
    return new Promise(function (resolve, reject) {
        api.get(url)
            .then(function (response) {
                // var responseData = response.data;
                // console.log('responseData', responseData);
                // if (responseData.success) {
                //   resolve(makeCompatibleComboOptions(responseData.data));
                // } else {
                //   reject(new Error(defaultErrorMessage));
                // }

                if (response.success) {
                    const responseData = response.data;
                    console.log('responseData', responseData);
                    resolve(makeCompatibleComboOptions(responseData));
                } else {
                    reject(new Error(defaultErrorMessage));
                }
            })
            .catch(function (error) {
                console.error(error);
                reject(new Error(defaultErrorMessage));
            });
    });
}

const parseJwtInternal = (token) => {
    if (token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let decoded = JSON.parse(jsonPayload);
        delete decoded['iat'];
        delete decoded['exp'];
        return decoded;
    }
}

function extractTokenData(decodedToken) {
    const {
        securityInformation: {id: userId, roleId, isAgency, isNurse, agencyId, walletId, isAdmin},
        personalInformation: {chatId, firstName, lastName, profileImgLink, timeZoneOffset}
    } = decodedToken;
    // return {token, data: {userId, isAgency, isNurse, roleId, chatId, firstName, lastName, agencyId, walletId, profileImgLink, isAdmin, timeZoneOffset}};
    return {userId, isAgency, isNurse, roleId, chatId, firstName, lastName, agencyId, walletId, profileImgLink, isAdmin, timeZoneOffset};
}

export const validateLoginArea = (userType, token) => {
    const decodedToken = parseJwtInternal(token).data;
    const decodedTokenData = extractTokenData(decodedToken);
// console.log('test- userType', userType, decodedTokenData)
    let invalidLoginArea = false;
    if (userType === 'admin' && !decodedTokenData.isAdmin) {
        invalidLoginArea = true;
    }
    if (userType === 'agency' && !decodedTokenData.isAgency) {
        invalidLoginArea = true;
    }
    if (userType === 'nurse' && !decodedTokenData.isNurse) {
        invalidLoginArea = true;
    }
    return {invalidLoginArea, decodedTokenData};
};

export const parseTokenAndGetData = () => {
    const token = storageManager.retrieveToken();
    if (token == null) return {token: null, data: null};
    const decodedToken = parseJwtInternal(token).data;
// console.log('decodedToken', decodedToken);
    return {token, data: extractTokenData(decodedToken)};
}

export const isLoggedIn = () => {
    return storageManager.retrieveToken() != null;
}

export const redirectOrLogout = (dispatch, navigate) => {
    if (isLoggedIn()) {
        const decodedTokenData = parseTokenAndGetData().data;
        if (decodedTokenData.isAdmin) {
            navigate("/admin/dashboard");
        }
        if (decodedTokenData.isAgency) {
            navigate("/agency/dashboard");
        }
        if (decodedTokenData.isNurse) {
            navigate("/nurse/dashboard");
        }
    } else {
        dispatch({
            type: `AUTH_SAVE`,
            payload: undefined,
            name: 'authData',
            meta: { type: 'SAVE' },
        });
    }
}

export const generateUniqueId = () => {
    const timestamp = Date.now().toString(); // Gets the current time in milliseconds
    const randomPart = Math.random().toString(36).substring(2, 10); // Generates a random string
    return (timestamp + randomPart).padEnd(20, '0'); // Combines them and ensures the length is 20
}
export const isAgencyUser = () => {
    const decodedTokenData = parseTokenAndGetData().data;
    return decodedTokenData != null && decodedTokenData.isAgency;
}

export const isNurseUser = () => {
    const decodedTokenData = parseTokenAndGetData().data;
    return decodedTokenData != null && decodedTokenData.isNurse;
}

export const isAdminUser = () => {
    const decodedTokenData = parseTokenAndGetData().data;
    return decodedTokenData != null && decodedTokenData.isAdmin;
}

export const getDownloadUrl = (key, name, clientIdOrNurseIdOrAgencyId, id) => {
    return `${apiBaseUrl}${getApiUrl(key, name)}/${clientIdOrNurseIdOrAgencyId}/${id}`;
}

export const createDownloadUrl = (path) => {
    return `${apiBaseUrl}${path}`;
}

export const getPaypalAmountWithChargesAdded = (amountToAdd) => {
    return numberHelperFunctions.round(100 * amountToAdd / (100 - paypalDeductionPercentage));
}

export const isAdminRole = isAdminUser();
export const isNurseRole = isNurseUser();
export const isAgencyRole = isAgencyUser();
