export const AGENCY_TRANSACTION_LIST = 'AGENCY_TRANSACTION_LIST';
export const SUBSCRIPTION_HISTORY_LIST = 'SUBSCRIPTION_HISTORY_LIST';
export const SUBSCRIPTION_PLAN_LIST = 'SUBSCRIPTION_PLAN_LIST';
export const UPGRADE_SUBSCRIPTION_PLAN_LIST = 'UPGRADE_SUBSCRIPTION_PLAN_LIST';
export const PAYPAL_PAYMENT_ORDER = 'PAYPAL_PAYMENT_ORDER';
export const ADD_FUND = 'ADD_FUND';
export const ADD_FUND_TEMP_DATA = 'ADD_FUND_TEMP_DATA';
export const ADD_FUND_PAYMENT_ORDER = 'ADD_FUND_PAYMENT_ORDER';
// export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const GET_AGENCY_WALLET_AMOUNT = 'GET_AGENCY_WALLET_AMOUNT';









